export function activeDropReducer(state = {type:'',data:{}}, action){
  switch (action.type) {
    case "GET_DROP_REQUEST":
      return  {type:'request',data:{}}
    case "GET_DROP_SUCCESS":
      return  {type:'success',data:action.data}
    case "LOGIN_SUCCESS":
      return  {type:'success',data:action.drop}
  }

  return state;
}


export function previousDropsReducer(state = {type:'',data:{}}, action){
  switch (action.type) {
    case "GET_PREVIOUS_DROPS_REQUEST":
      return  {type:'request',data:{}}
    case "GET_PREVIOUS_DROPS_SUCCESS":
      return  {type:'success',data: action.data}
  }

  return state;
}


export function pageAreaReducer(state = {}, action){
  switch (action.type) {
    case "PAGE_SET_AREA":
      return action.data;
  }
  return state;
}


export function setActiveDropDuration(state = "", action){
  switch (action.type) {
    case "SET_DROP_DURATION":
      return action.data;
  }
  return state;
}

export function setFormFun(state = ()=>{}, action){
  switch (action.type) {
    case "SET_FORM_FUN":
      return action.data;
  }
  return state;
}


export function setBackbutton(state = null, action){
  switch (action.type) {
    case "SET_BACKBUTTON":
      return action.data;
    case "RESET_BACKBUTTON":
      return null;
  }
  return state;
}
