import React from 'react'
import PropTypes from 'prop-types'

function Field(props) {
  const {info,cssName,value,changeEvent} = props;

  const handleChange = (e)=>{
    console.log("info.field_name,e.target.value",info.field_name,e.target.value)
    changeEvent(info.field_name,e.target.value);
  }

  const handleOption = (e)=>{
    let option;
    if(value==""){
      option = [...value]
    }else{
      option = value;
    }
    if(e.target.checked){
      option.push(e.target.value);
    }else{
      const index = option.indexOf(e.target.value);
      if (index > -1) {
        option.splice(index, 1); // 2nd parameter means remove one item only
      }
    }
    changeEvent(info.field_name,option);
  }

  return (
    <div className="mb-4">
    <label htmlFor="name" className="form-label">{info.field_name} {info.required ? "*":""}</label>
    {['text','url','email'].includes(info.field_type) &&
       <input type={info.field_type} className={cssName('form-control',info.field_name)} id={info.field_name} placeholder={info.field_name} value={value} onChange={handleChange}/>
    }

    {info.field_type == 'textarea' &&
     <textarea className={cssName('form-control',info.field_name)} row="5" onChange={handleChange}>{value}</textarea>
    }


    {info.field_type == 'select' &&
     <select name={info.field_name} className={cssName("form-select",info.field_name)} id={info.field_name} placeholder={info.field_name} value={value} onChange={handleChange}>
        <option value={""}>{"---Select---"}</option>
      {info.field_options.map((option,index) => { 
          return  <option key={index} value={option}>{option}</option>
        })}
       {info.field_options.map((option,index) => { <option value={option} key={index}>{option}</option>})}
      </select>
    }
    

   {info.field_type == 'checkbox' &&
      <>
      {info.field_options.map((option,index) => { 
          return <div class="form-check text-white" key={index}>
                  <input name={info.field_name} className={cssName("form-check-input",info.field_name)}  type="checkbox" value={option} id={`flexCheckDefault-${option}`} onChange={handleOption}  checked={Array.isArray(value) && value.includes(option)}/>
                  <label className="form-check-label" for={`flexCheckDefault-${option}`}>
                    {option}
                  </label>
                </div>
        })}
      </>
    }

    {info.field_type == 'radio' &&
     <>
      {info.field_options.map((option,index) => { 
          return <div class="form-check text-white" key={index}>
                  <input name={info.field_name} className={cssName("form-check-input",info.field_name)}  type="radio" value={option} id={`flexCheckDefault-${option}`} checked={value == option} onChange={handleChange}/>
                  <label className="form-check-label" for={`flexCheckDefault-${option}`}>
                    {option}
                  </label>
                </div>
        })}
      </>
    }


  </div>
  )
}

Field.propTypes = {

}

export default Field

