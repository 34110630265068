import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';


const IndividualSponsor = (props) => {
  const { sponsor } = props;
  const openPage = ()=>{
    if(sponsor.sponsor.external_url != null  && sponsor.sponsor.external_url.trim() != ''){
      window.open(sponsor.sponsor.external_url, '_blank');
    }
   
  }
  return (
    
    <div className="cardz multi-sponsor-card" onClick={openPage} style={{cursor:'pointer'}}>
      <div className="card-body">
        <img src={sponsor.sponsor.logo_url} alt={sponsor.sponsor.name} className="img-fluid rounded-circle" width="99" />
        <div className="mt-3">
            <span className="heading_sm text-decoration-underline">{sponsor.sponsor.name}</span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  page: state.page,
  activeDrop: state.activeDrop,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualSponsor);

