import React,{useState} from 'react';
import { connect } from 'react-redux'
import {  toast } from 'react-toastify';

import {Link} from "react-router-dom";

import {registerForDrop} from "../../services/actions";
import {showUTCTime,addToCalendar,copyText} from "../../utils/index"
import * as constants from "../../services/constants/index"
import Notify from '../Notify';


function Action(props) {
  const {user,location,dropDuration,activeDrop} = props;
  const [request,setRequest] = useState(false);
  let base_url ="/drop/"+activeDrop.data.url_handle;
  const registerDrop = ()=>{
    // setRequest(true);
    //  to="/user-registered"
    // console.log("props",props);
    // return console.log("user,location,dropDuration,activeDrop",user,location,dropDuration,activeDrop);
    const data = {eth_address:user.data.eth_address,drop_id:activeDrop.data.id,nft_address:user.dropNft.nftAddr,nft_token_id:user.dropNft.tokenId}
    console.log("data",data);
    props.registerForDrop(data,(result)=>{
      if(result.type =='success'){
        props.history.push("/user-registered");
      }else{
        setRequest(false);
        alert(result.message.join(","));
        window.location.reload();
      }
    });
  }

  const share_twitter = (type)=>{
    let text =`Unlock new rewards for different NFT communities every week with PXDrops! Follow @PolyientX and see if your community is eligible for a free reward. 👉`;


    if(type =='registered'){
      text = `Just registered for PXDrops! Unlock new rewards for different NFT communities every week. Follow @PolyientX and see if your community is eligible for a free reward.`
    } 

    if(type =='claimed'){
      text = `Just claimed a Tshirt  for holding a ${activeDrop.data.dropName} with PXDrops! Unlock new rewards for different NFT communities every week. Follow @PolyientX and see if your community is eligible for a free reward.`
    } 


    window.open("https://twitter.com/share?text="+text+"&url=https://www.pxdrops.com/drop/"+activeDrop.data.url_handle+"&hashtags=PXDrops,NFTs,NFTCommunity");
    // constants.GPageEvent("Social","Twitter-Share","Share");
  }

  console.log("props.dropDuration =>",props.dropDuration)
  return (
    <section className="register">
       <div className="container">
        <div className="row justify-content-center">
        <div className="col-lg-10">
       
      {location.pathname ==base_url && (props.dropDuration > 0) ?
        <div className="my-3">
          <div className='registration d-flex justify-content-center'>
            <Link to="/connect" className="registration-start">Register to Claim </Link>
          </div>
          <div className='share d-flex justify-content-evenly'>
            <p className='share-twitter text-white' onClick={()=>{share_twitter('default')}}><i className="ri-twitter-line" /> <b>Share on Twitter</b></p>
          </div>
        </div>
      :
      <div className="text-centered">
        { (location.pathname ==base_url && (dropDuration == "0") && activeDrop.data.available_drops == 0) &&
          <Notify {...props} no_spacing="true" />
        }
      </div>
      }

      {/* {location.pathname ==base_url && (props.dropDuration > 0 && (props.dropDuration._milliseconds/1000) < 10800 ) &&
        <div className="my-3">
          <div className='registration'>
            <h5 className="text-white register-title">Registration is closed now.</h5>
            <Link to="#" className="share-twitter  me-3 actionBtn"  onClick={()=>{share_twitter('default')}}><i className='ri-twitter-line'></i> Share on Twitter</Link>
          </div>
           <div className='share d-flex justify-content-evenly'>
            <button onClick={()=>{document.getElementById('next_drop_email').focus()}}>Want to be notified about the next drop?</button>
          </div>
        </div> 
      } */}

      {location.pathname =="/delivery" &&
        <div className="my-3">
          <div className='registration'>
            <h5 className="text-white register-title"> </h5>
            <Link to="#" className="share-twitter  me-3 actionBtn"  onClick={()=>{share_twitter('claime')}}><i className='ri-twitter-line'></i> Share on Twitter</Link>
          </div>
           <div className='share d-flex justify-content-evenly'>
            <button onClick={()=>{document.getElementById('next_drop_email').focus()}}>Want to be notified about the next drop?</button>
          </div>
        </div> 
      }

     {![base_url,"/connect","/claim-connect","/address","/delivery","/notqualified",'/complete','/user-registered','/claim-disqualified','/not-registered', "/previous-drop-detail"].includes(location.pathname) &&
       <div className="my-3">
        <div className='registration'>
          {/* <h5 className="text-white register-title">Connect Your Wallet</h5> */}
          <Link to="/connect" className="me-3 actionBtn" style={{fontSize:"1rem", padding: '1.2rem 2rem'}}>Choose Wallet to Connect</Link>
        </div>
        <div className='share d-flex justify-content-evenly'>
          <button onClick={()=>{props.history.push(base_url)}}>Cancel</button>
        </div>
      </div> 
      }

    {['/claim-disqualified','/not-registered'].includes(location.pathname) &&
       <div className="my-3">
        <div className='d-flex justify-content-evenly'>
          <button onClick={()=>{props.history.push(base_url)}}>Back to Drop Homepage</button>
        </div>
      </div> 
      }

    {['/previous-drop-detail'].includes(location.pathname) &&
       <div className="my-3 d-lg-none d-sm-block">
        <div className='d-flex justify-content-evenly'>
          <button onClick={()=>{props.history.push('/previous-drops')}}>Back to Drop Homepage</button>
        </div>
      </div> 
      }  

    {([base_url].includes(location.pathname) && (dropDuration === 0)  && activeDrop.data.available_drops > 0) &&
          <div className="my-3">
            {user.type!="success" ? 
            <div className='registration d-flex justify-content-evenly'>
              {/* <h5 className="text-white register-title">Connect Your Wallet</h5> */}
              <Link to="/claim-connect" className="claim-start me-3 actionBtn" style={{fontSize:"1rem", padding: '1.2rem 2rem'}}> Claim Now</Link>
            </div>
            :
            <div className='registration d-flex justify-content-evenly'>
              <Link to="/claim-connect" className="claim-start me-3 actionBtn" style={{fontSize:"1.15rem"}}>Claim Now</Link>
            </div>
            }
           <div className='share d-flex justify-content-evenly'>
            <p className='share-twitter  text-white'  onClick={()=>{share_twitter('default')}}><i className="ri-twitter-line" /> <b>Share on Twitter</b></p>
          </div>
          
          </div> 
    }

      {/* <div className="my-3">
        <div className='registration align-items-center'>
          <h5 className="text-white register-title">Register now to secure your chance to win</h5>
          <Link to="/connect" className="me-3 backbtn">Back to Drop Homepage</Link>
        </div>
        <div className='share d-flex justify-content-evenly'></div>
      </div> */}

    {["/complete"].includes(location.pathname) &&
      <div className="my-3">
        <div className='registration  d-flex justify-content-center'>
          {/* <h5 className="text-white register-title">Click for register for Drop</h5> */}
          <Link to="#" className="" onClick={registerDrop} disabled={request}> Register For Drop</Link>
        </div>
        <div className='shared d-flex justify-content-center pt-3'>
          <button onClick={()=>{props.history.push(base_url)}}>Cancel</button>
        </div>
      </div>
    }

    {["/user-registered"].includes(location.pathname) &&
      <div className="my-3">
        <div className='registration d-flex justify-content-center'>
          {/* <h5 className="text-white register-title">Please save date to your calendar</h5> */}
          <Link to="#" className=""  onClick={()=>{addToCalendar(activeDrop.data)}} style={{cursor:"pointer"}}><i className="ri-add-circle-line"></i> Add to Google Calendar</Link>
        </div>
        <div className='share d-flex justify-content-evenly'>
          <p className='share-twitter  text-white' onClick={()=>{share_twitter('registered')}}><i className="ri-twitter-line" /> <b>Share on Twitter</b></p>
        
        </div>
      </div> 
    }

    {["/address"].includes(location.pathname) &&
      <div className="my-3">
        <div className='registration  d-flex justify-content-center'>
          {/* <h5 className="text-white register-title">Click for register for Drop</h5> */}
          <button  id="claim_form" className="claim actionBtn" onClick={props.claimFormSubmit} > Submit</button>
        </div>
        <div className='shared d-flex justify-content-center pt-3'>
          <button onClick={()=>{props.history.push(base_url)}}>Cancel</button>
        </div>
      </div>
      }  
        </div> 
        </div> 
        </div> 
    </section>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
  claimFormSubmit:state.claimFormSubmit,
})

const mapDispatchToProps = {
  registerForDrop
}

export default connect(mapStateToProps, mapDispatchToProps)(Action)