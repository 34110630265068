import React,{useEffect,useState} from 'react'
import PropTypes from 'prop-types';

import Header from "../components/Header"
import Tshirt from '../components/Tshirt';
import TimerClaim from '../components/Tshirt/timer_claim';
import Notify from '../components/Notify'

import Footer from "../components/Footer"
import FooterAction from "../components/Footer/action"
import { withRouter } from 'react-router-dom';
import TimerBanner from '../components/Tshirt/timer_banner';

import moment from "moment";

function watchTimeForDrop(activeDrop,setDuration,uf){
  uf(()=>{
    var eventTime= moment.utc(activeDrop.data.drp_date_time_utc).unix(); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
    var currentTime = moment().utc().unix(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
    var diffTime = eventTime - currentTime;
    var duration = moment.duration(diffTime*1000, 'milliseconds');
    var interval = 1000;

    let timeInterval = setInterval(function(){
      duration = moment.duration(duration - interval, 'milliseconds');
      if(duration > 0 ){
        setDuration(duration)
      }else{
        setDuration(0)
      }
    }, interval);
    return ()=>{
      clearInterval(timeInterval);
    }
  },[activeDrop])

}

export const LayoutOne = withRouter(props => {
  watchTimeForDrop(props.activeDrop,props.setActiveDropDuration,useEffect);
  let base_url ="/drop/"+props.activeDrop.data.url_handle;

  return (
    <>
      <section className="px-drops">
       
        <div className="container-fluid">
          {
            props.page.width < 768 &&
            <Header {...props}/>
          }
          <div className="row">
            <div className="col-lg-4 free-tshirt px-0">
              {
                props.page.width < 768 ?
                  (['/register','/connect','/complete','/user-registered','/notqualified','/delivery','/claim-disqualified','/not-registered'].includes(props.location.pathname)) ?
                    <></>
                  :
                  <Tshirt {...props}/>
                :
                <Tshirt {...props}/>
              }
            </div>
            <div className="col-lg-8 nft-drop px-0">
              {
                props.page.width >= 768 &&
                <Header {...props}/>
              }
              <section className={ (props.page.width < 768 && (['/register','/connect','/complete','/user-registered'].includes(props.location.pathname))) ? 'content outer_content_area' : 'content outer_content_area'}>
                {props.children}
                {
                  (['/user-registered', '/not-registered', base_url, '/delivery', '/claim-disqualified'].includes(props.location.pathname) && (props.activeDrop.data.available_drops != 0 )) &&
                  <Notify {...props}/>
                }
                <Footer {...props}/>
              </section>
              <FooterAction {...props}/>
              </div>
          </div>
        </div>
      </section>
    </>
  )
})

export const LayoutTwo=(props)=>{
  watchTimeForDrop(props.activeDrop,props.setActiveDropDuration,useEffect);
  return (
    <>
      <section className="px-drops">
        <div className="container-fluid">
          <Header nav_align="right" {...props}/>
          <div className="row">
            <section className="content prev_sponsor_content">
              {props.children}
            </section>
          </div>
        </div>
      </section>
    </>
  )
}

export const LayoutPreviousDropDetail = withRouter(props => {
  return (
    <>
      <section className="px-drops">
       
        <div className="container-fluid">
          {
            props.page.width < 768 &&
            <Header {...props}/>
          }
          <div className="row">
            <div className="col-lg-4 free-tshirt px-0">
              {
                props.page.width < 768 ?
                  (['/register','/connect','/complete','/user-registered','/notqualified','/delivery','/claim-disqualified','/not-registered'].includes(props.location.pathname)) ?
                    <></>
                  :
                  <Tshirt {...props}/>
                :
                <Tshirt {...props}/>
              }
            </div>
            <div className="col-lg-8 nft-drop px-0">
              {
                props.page.width >= 768 &&
                <Header {...props}/>
              }
              <section className={ (props.page.width < 768 && (['/register','/connect','/complete','/user-registered'].includes(props.location.pathname))) ? 'content prev_sponsor_content' : 'content prev_sponsor_content'}>
                {props.children}
                <Notify {...props}/>
                <Footer {...props}/>
              </section>
              <FooterAction {...props}/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
})

export const LayoutThree = withRouter(props => {
  watchTimeForDrop(props.activeDrop,props.setActiveDropDuration,useEffect);
  return (
    <>
      <section className="px-drops">
        <div className="container-fluid">
          {
            props.page.width < 768 &&
            <Header {...props}/>
          }
          <div className="row">
            <div className="col-lg-4 free-tshirt px-0">
            {
                props.page.width < 768 ?
                <div className='card'>
                <TimerClaim/>
                </div>
                :
                <Tshirt {...props}/>
              }
              
            </div>
            <div className="col-lg-8 nft-drop px-0">
              {
                props.page.width >= 768 &&
                <Header {...props}/>
              }
              <section className="content outer_content_area">
                  {props.children}
                  {/* <Notify/> */}
                  <Footer {...props}/>
              </section>
              <FooterAction {...props}/>
              </div>
          </div>
        </div>
      </section>
    </>
  )
})