import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom';

import moment from 'moment';
// import {} from "../../utils/index"

const DropTimer = withRouter(props => {
  const{activeDrop,dropDuration} = props;

  if(dropDuration===""){
    return null;
  }

  return <>
    {
       dropDuration === 0 ?
        <>
        {activeDrop.data.available_drops > 0  ?
        <br/>
        :
        <div className="drop-timer" id="droptimer">
          <h5 className="card-title text-center bg-primary-2">
            <i className="ri-information-line me-1"></i> DROP COMPLETED
          </h5>
        </div>
        }
        </>
      :
        <>
        </>
    }
    
  </>;
});



const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DropTimer)

// export default DropTimer;