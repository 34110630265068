import React from 'react'

function Collectioncard(props) {
  const {user,activeDrop} = props;
    return (
      <>
        <div className="card collection-card my-3" style={{width: '20rem'}}>
          <div className="row g-3 align-items-center">
            <div className="col-4">
              <img src={activeDrop.data.logo_url} className="img-fluid rounded-circle" alt={activeDrop.data.dropName} />
            </div>
            <div className="col-8">
              <div className="card-body">
                <h5 className="heading_h3 text-white">{activeDrop.data.nft_name}</h5>
                <a href={activeDrop.data.collection_url} target="_blank" rel="noreferrer" className="nft-project-site-1 card-text align-items-center">Browse Collection <i className="ri-external-link-line ms-2 nft-project-site" /></a>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default Collectioncard
