import React,{useState, useEffect} from 'react'
import { connect } from 'react-redux'
import Footer from '../../components/Footer';
import Notify from '../../components/Notify';
import Pxdrops from '../../components/px-drops';
import Recommend from '../../components/Recommend';

import {ValidateEmail} from "../../utils/index"

import {subscribeEmail, getPreviousDrops} from "../../services/actions";
import { PreviousDropsBox } from '../../components/px-drops/previous_drop';

const PreviousDrops = (props) => {
  const {previousDrops} = props;
  useEffect(() => {
    props.getPreviousDrops();
  }, []);

  return (
    <div className="container mt-4">
      <div className="row justify-content-center sponsor">
        <div className="col-xl-8">
          <h1 className="heading_lg">Previous Drops</h1>
          {/* <h3 className="heading_h3" style={{color: '#C7C8C9'}}>Browse previous NFT drops, don't worry - many more where these came from!</h3> */}


          {/* all drops details */}
          {
            (previousDrops.type === 'success' && previousDrops.data.length>0) ?

            previousDrops.data.map((drop, index) => {
              return (
                <PreviousDropsBox key={index} drop={drop} {...props} />
              )
            })

            :

            <div className="text-center">
              <h4 className="text-white mt-4">
                {
                  (previousDrops.type === 'success' && previousDrops.data.length===0) ?
                  'No drops found'
                  :
                  'Loading...'
                }
              </h4>
            </div>
          }
          <br/>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
  previousDrops:state.previousDrops,
})

const mapDispatchToProps = {
  subscribeEmail,
  getPreviousDrops,
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousDrops)
