import React from 'react'

function SponsorCard(props) {
  const {user,activeDrop, page} = props;
  
  return (
    <>
      <div className={"container mb-5"}>
        <div className={"row justify-content-center"}>
          <div className={"col-lg-10"}>
            <div className="card sponsor-card" >
              <div className="card-body">
                <h5 className="card-title">
                  <div className="d-flex justify-content-start align-items-center">
                    <img src={activeDrop.data.sponsor_logo_url} className="me-2 rounded-circle" height="45" width="45" alt="" />
                    <span>
                      About the Sponsor  <a href={activeDrop.data.sponsor_external_link} target="_blank"><u className="text-white">{activeDrop.data.sponsored_display_name}</u></a>
                    </span>
                  </div>
                </h5>
                <p className="card-text">{activeDrop.data.about_sponsor}</p>
                {/* activeDrop.data.sponsor_external_link */}
                <a href={"https://polyientx.com/sponsor-pxdrops"} target='_blank' className="gradient_txt">Become a Drop Sponsor <i className="ri-external-link-line"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SponsorCard
