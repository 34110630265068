import React from 'react'
import { connect } from 'react-redux'
import Collectioncard from '../../components/Collectioncard';
import { ConnectClaimStep } from '../../components/ConnectStep'
import Instructions from '../../components/instructions';
import Pxdrops from '../../components/px-drops';
import Recommend from '../../components/Share/index';
import SponsorCard from '../../components/SponsorCard';
import WinTicket from '../../components/WinTicket';
import RewardsBox from '../../components/RewardsBox';

import {showUTCTime,addToCalendar} from "../../utils/index"

function RegistrationMissed(props) {
  const {user,activeDrop,page, setDisplayMultiSponsor} = props;

    return (
      <>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 mt-5">
              {props.page.width >= 768 &&
                <div>
                  <h1 className="text-white heading">Rewards for NFT Communities</h1>
                  <p className='heading_h4'>We reward NFT communities with merch, digital goods and more. </p>
                  <RewardsBox {...props} />

                  <div className="no-sweet mb-4">
                    <h4 className='heading_normal' style={{fontSize: '18px', color: '#C7C8C9'}}>Sorry you missed out</h4>
                    <h3 className='text-white'>But wait, there's more...</h3>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-12">
            <WinTicket {...props} close={true} setDisplayMultiSponsor={setDisplayMultiSponsor} hide_sponsor={true}/>
            <br/>
          </div>
        </div>
      </>
    )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationMissed);