import React from 'react'
import SponsorCard from '../../components/SponsorCard'
import Pxdrops from '../../components/px-drops'
import Recommend from '../../components/Recommend'

import { connect } from 'react-redux'

function Claim(props) {

    return (
        <>
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 ">
                        <h1 className='save-date'>You're Registered! But You're Not Done Yet...</h1>
                        <p className='sub-paragraph pt-3'>Don't forget to come back on the Drop Claim Date to claim your Reward before they're all gone!</p>
                    </div>
                </div>
            </div>

            <div className="pt-4">
                <SponsorCard {...props}/>
            </div>
            
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 ">
                        <div className="row justify-content-center">
                                <div className="col-lg-10 nft-drops pt-5">
                                    <Pxdrops {...props}/>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <Recommend {...props}/>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => ({
    user: state.user,
    page: state.page,
    activeDrop:state.activeDrop,
    dropDuration:state.dropDuration,
  })
  
  const mapDispatchToProps = {
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Claim)
  