import React,{useState} from 'react'
import PropTypes from 'prop-types'
import Toast from 'react-bootstrap/Toast'

function ToastObj(props) {
  const [show,setShow]= useState(true);
  const close = ()=>{
    if(props.close){
      props.close();
    }
    setShow(false);
  }
  return (
    <>
    <div className="position-absolute" style={{top:'15px',right:'10px',zIndex:'10006'}}>
      <Toast onClose={() => close()} show={show} delay={3000} className={`bg-${props.type} text-white`} autohide>
        <Toast.Body>{props.message}</Toast.Body>
      </Toast>
    </div>
    </>
  )
}

ToastObj.propTypes = {

}

export default ToastObj

