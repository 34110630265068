import React from 'react'
import {ConnectClaimStep} from '../../components/ConnectStep'
import Walletgroup from '../../components/Walletgroup'
import Pxdrops from '../../components/px-drops'

function ClaimStepTwo() {
    let title = "Choose Your Wallet"
    let src = "/assets/images/Mask group.png"
    return (
        <>
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <ConnectClaimStep step_number={1} title={title} src={src} checked={false}/>
                        <h3 className="heading_h3">Select your preferred wallet below to connect and claim.</h3>
                        <Walletgroup  />
                        
                        <div className="row justify-content-center">
                            <div className="col-lg-10 nft-drops pt-5 pb-4">
                                {/* <Pxdrops/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClaimStepTwo
