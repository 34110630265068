import React from 'react'
import { connect } from 'react-redux'

import {ConnectClaimStep} from '../../components/ConnectStep'
import SponsorCard from '../../components/SponsorCard'
import Pxdrops from '../../components/px-drops'

function Delivery(props) {
    const {user, page, activeDrop, dropDuration} = props
    let title = "You Got One!"
    let src = "/assets/images/pug-rounded.png"
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <ConnectClaimStep step_number="3" title={title} src={src} {...props} checked={true}/>

                        <div className="pt-4 expected-delivery">
                            <h3 className="heading_h3 mb-3">Here's What Happens Next:</h3>
                            <p className="d-flex align-items-center"><i className="ri-truck-line me-2"></i> {activeDrop.data.delivery_notes}</p>

                            <p className="text-warning">{activeDrop.data.claim_success_notes}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <SponsorCard {...props}/>
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 nft-drops pt-5">
                                <Pxdrops {...props}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    page: state.page,
    activeDrop:state.activeDrop,
    dropDuration:state.dropDuration,
  })
  
  const mapDispatchToProps = {
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Delivery);