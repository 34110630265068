import React,{useRef} from 'react'
import {Redirect} from "react-router-dom";
import Toast from "../../components/Toast"

import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import {userLogin,userReset} from "../../services/actions"
import { duration } from 'moment';


function Walletgroup(props) {
  const {user,activeDrop,dropDuration} = props;
  const login = (type)=>{
      if(user.type!='request'){
       props.userLogin(type,activeDrop.data);
      }
  } 

  const redirectURL =()=>{
    console.log("dropDuration",dropDuration);
    console.log("user",user);
    if(dropDuration > 0){
          if(user.dropNft ==null){
            return '/notqualified'
          }

          if(user.register !=null){
            return "/user-registered";
          }

            return '/complete'
      }else{
          if(user.claim && user.claim.status ==true){
            return "/delivery"
          }
          if(user.register !=null){
            if(user.dropNft ==null){
              return '/claim-disqualified'
            }else{
              if(activeDrop.data.available_drops ==0){
                return "/drop/"+activeDrop.data.url_handle;
              }else{
                return '/address'
              }
            }
          }else{
            return '/not-registered'
          }
      }
     
  }

    return (
      <>
      {user.type=="error" && 
        <Toast type="danger" message={user.message} close={()=>{props.userReset()}}/>
        }
        {user.type=='success' &&
            <Redirect to={redirectURL()}/>
        }
        <div className="walletRegister mt-4">
          <div className="row mt-3">
            <div className="col-6">
                <div className="card mt-2 py-3">
                  <div className="card-body" onClick={()=>{login('metamask')}}>
                    <div className='d-flex justify-content-center' >
                      <img src="/assets/images/metamask.png" alt="" />
                    </div>
                    <div className="card-title text-center pt-4">Metamask</div>
                  </div>
                </div>
            </div>
            <div className="col-6">
                <div className="card mt-2 py-3">
                  <div className="card-body" onClick={()=>{login('wallet')}}>
                    <div className='d-flex justify-content-center'>
                      <img src="/assets/images/wallet.png" alt="" />
                    </div>
                    <div className="card-title text-center pt-4">Wallet Connect</div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </>
    )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
  userLogin,
  userReset
}

export default connect(mapStateToProps, mapDispatchToProps)(Walletgroup)

