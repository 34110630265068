import React, {useState} from 'react'
import { connect } from 'react-redux'

import {ConnectStep} from '../../components/ConnectStep'
import Share from '../../components/Share'
import Pxdrops from '../../components/px-drops'
import Recommend from '../../components/Recommend'
import WinTicket from '../../components/WinTicket';
import Notify from '../../components/Notify'
import MultiSponsorList from '../../components/MultiSponsor/list';

function Notqualified(props) {
  const {activeDrop,page} = props;
  const [displayMultiSponsor, setDisplayMultiSponsor] = useState(false);
    return (
      <>
      {
        !displayMultiSponsor ?
        <div>
          <div className="container" style={ props.page.width < 768 ?  {} :{}}>
            <div className="row justify-content-center">
              <div className="col-lg-10 ">
                <div className="card collection-card my-3">
                  <div className="row g-0">
                    <div className="col-12">
                      <div className="card-body">
                        <div className="stepss">
                          <h5 className="card-title text-white">You’re Not Eligible to Register</h5>
                          <h5 style={{color: '#ECE75B'}}>We didn’t find an eligible NFT in your  wallet but you can still...</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

        
                <div className="no-sweet my-5">
                  <h3 className="heading_md" style={{color: '#C7C8C9'}}>Sorry you missed out</h3>
                  <h4 className='heading_h3'>But wait, there's more...</h4>
                </div>

                
              </div>
            </div>
          </div>

          <WinTicket {...props} close={true} setDisplayMultiSponsor={setDisplayMultiSponsor} />

          <div className='mt-4'>
            <Notify {...props}/>
          </div>
        </div>
        :
        <MultiSponsorList {...props} actualDrop={activeDrop.data} sponsorList={setDisplayMultiSponsor} from_opensea="true" />
      }
        
      </>
    )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Notqualified)
