import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import MultiSponsorList from './list';

const MultiSponsor = (props) => {
  const { page, activeDrop, setDisplayMultiSponsor } = props;
  const [sponsorList, setSponsorList] = useState(false);
  return (
    <>
      <div className="d-flex align-items-start my-3">
        <div className="">
          <h5 className="heading_md" style={{color: '#C7C8C9'}}>An exclusive reward for</h5>
          <h4 className="heading_h3 mb-0">{activeDrop.data.nft_name}</h4>
        </div>
      </div>

      <div className="multi_sponsors mb-3 cursor-pointer" onClick={() => setDisplayMultiSponsor(true)}>
        {activeDrop.data.sponsors_data.map((v,index)=>{ return (index < 3) ? <img src={v.sponsor.logo_url} alt={v.sponsor.name} className={index == 0 ? "rounded-circle" : "ms-n5 rounded-circle"} width="85" key={index} /> : null})}
        {activeDrop.data.sponsors_data.length > 3 &&
          <span className="sponsor_cnt">+{activeDrop.data.sponsors_data.length-3}</span>
        }
      </div>

      <div className="multi_sponsor_detail mb-3"  onClick={() => setDisplayMultiSponsor(true)}>
      {activeDrop.data.sponsors_data.map((v,index)=>{ return (index < 3) ? <span key={index}><a href={v.nft_collection_url} target="_blank" rel="noreferrer">{v.sponsor.name}</a>  <span>•</span></span> : null})}

        {activeDrop.data.sponsors_data.length > 3 &&
         <a href="#more">+more</a>
        }
      </div>
      {
        sponsorList &&
        <MultiSponsorList {...props} actualDrop={activeDrop.data} sponsorList={setSponsorList} />
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  page: state.page,
  activeDrop: state.activeDrop,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiSponsor);

