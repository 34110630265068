import React from 'react'
import { connect } from 'react-redux'

import Collectioncard from '../../components/Collectioncard';
import { ConnectClaimStep } from '../../components/ConnectStep'
import Instructions from '../../components/instructions';
import WinTicket from '../../components/WinTicket';
import Pxdrops from '../../components/px-drops';

function NotRegisteredStep1(props) {
    let title = "No sweat - ";
    let src = "/assets/images/pug-rounded.png"
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="header_area my-5">
                          <h1 className="lg">No sweat - </h1>
                          <h1 className="md">Here are a few ways you can still benefit from this drop</h1>
                        </div>
                        
                        <div className="shop">
                          <h3>PROVIDE YOUR FEEDBACK</h3>
                          <p style={{color: '#FCFCFC'}}>Answer and be entered to win</p>
                          <p className='d-flex align-items-center'><i className="ri-ticket-line text-warning me-2"></i> Answer this poll to be entered to win a ticket to <span className="text-warning">&nbsp; NFT.NYC</span></p>
                        </div>

                        <div className="feedback_box my-5">
                          <h3>What types of rewards are you most interested in?</h3>
                          <div className="row mt-3">
                            <div className="col-md-3">
                              <div className="card">
                                <div className="py-3">
                                  <div><i className="ri-coin-line ri-2x gradient_txt"></i></div>
                                  <div>Tokens</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="card">
                                <div className="py-3">
                                  <div><i className="ri-t-shirt-line ri-2x gradient_txt"></i></div>
                                  <div>Merch</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="card">
                                <div className="py-3">
                                  <div><i className="ri-price-tag-3-line ri-2x gradient_txt"></i></div>
                                  <div>Discounts</div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="card">
                                <div className="py-3">
                                  <div><i className="ri-ticket-line ri-2x gradient_txt"></i></div>
                                  <div>Tickets</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                      <WinTicket {...props}/>

                        <div className="nft-drops pt-5">
                          <Pxdrops {...props}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(NotRegisteredStep1)
