import React from 'react'
import { connect } from 'react-redux'

import SponsorCard from '../../components/SponsorCard'
import Pxdrops from '../../components/px-drops'
import Collectioncard from '../../components/Collectioncard'
import Notify from '../../components/Notify'

function ClaimDay(props) {

    return (
        <>
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <h1 className='connectyourwallet'>It's Time To Claim Your Reward!</h1>
                        <p className='sub-heading-connection'>(Only pre-registered users are eligible to claim)</p>

                        <div className='my-4'>
                            <Collectioncard {...props}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <SponsorCard {...props}/>
            </div>
            
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 nft-drops pt-5 pb-3">
                                <Pxdrops/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Notify />
        </>
    )
}


const mapStateToProps = (state) => ({
    user: state.user,
    page: state.page,
    activeDrop:state.activeDrop,
    dropDuration:state.dropDuration,
  })
  
  const mapDispatchToProps = {
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(ClaimDay);