import React from 'react'
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

const PreviousSingleSponsor = (props) => {
  const { page, activeDrop } = props;
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0">
          <img src="/assets/images/sponsor_1.png" alt="Sponsor 1" className="img-fluid rounded-circle" />
        </div>
        <div className="flex-grow-1 ms-3">
          <h5 className="heading_sm" style={{color: '#C7C8C9'}}>An exclusive reward for</h5>
          <h3 className="heading_lg text-decoration-underline">Cool Cats</h3>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  page: state.page,
  activeDrop: state.activeDrop,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousSingleSponsor);

