import React from 'react'
import { connect } from 'react-redux';
import {Link} from "react-router-dom";

export const Footer = (props) => {
  return (
    <>
      <footer>
        <div className="container pt-5 pb-4">
          <div className="row justify-content-start">
            { false &&
            <div className='row'>
              <div className="col-lg-1"></div>
              <div className="col">
                <div className="col-lg-4 mb-3">
                  <h5 className="text-white">Connect with PolyientX</h5>
                  <div className="mb-4">
                    <a href="https://twitter.com/PolyientX" target="_blank" rel="noreferrer" className="text-decoration-none"><i className="ri-twitter-line ri-2x" /></a>
                    {/* <i className="ri-discord-line ri-2x" /> */}
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  {/* <h5 className="text-white">Other Info</h5> */}
                  <ul className="info">
                    <li className="info-item text-white"><a href="https://www.iubenda.com/privacy-policy/54937802" rel="noreferrer" target="_blank">Privacy Policy</a></li>
                    <li className="info-item text-white"><a href="https://www.iubenda.com/terms-and-conditions/54937802" rel="noreferrer" target="_blank">Terms and Conditions</a></li>
                    <li className="info-item text-white"><a href="https://www.iubenda.com/privacy-policy/54937802/cookie-policy" rel="noreferrer" target="_blank">Cookie Policy</a></li>
                  
                  </ul>

                
                </div>
                <h6 className="text-sm-start text-white mt-4 mb-5">©PolyientX, 2022</h6>
              </div>
            </div>
}
            <section style={{height: props.page.width < 768 ? '15vh' : '7.5vh'}} />
          </div>
        </div>
      </footer>
    </>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
