import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { showUTCTime3 } from '../../utils';
import MultiSponsor from '../MultiSponsor';
import SingleCollectionCard from '../Collectioncard/single';

export const PreviousDropsBox = (props) => {
  const { page, drop } = props;
  return (
    <>
      {
        page.width >= 768 ?
        <Link to={{pathname: `/previous-drop/${drop.url_handle}`, dropdata: drop}} className="card previous_drop_card my-4">
          <div className="row gutter-0">
            <div className="col-1 date_aside">
              <p>{showUTCTime3(drop.drp_date_time_utc)}</p>
            </div>
            <div className="col-7">
              <div className="card-bodys">
                <div className="row align-items-center">
                  <div className="col-12 py-3">
                    
                    <h4 className="title">{drop.banner_label}</h4>
                    {drop.sponsors_data.length > 1 &&
                      <h4 className="subtitle mt-3">{drop.nft_name}</h4>
                    }

                    {drop.sponsors_data.length > 1 ? 
                      <div className="mt-4">
                        <div className="multi_sponsors mb-3 cursor-pointer">
                          {drop.sponsors_data.map((v,index)=>{ return (index < 3) ? <img src={v.sponsor.logo_url} alt={v.sponsor.name} className={index == 0 ? "img-fluid rounded-circle" : "img-fluid rounded-circle ms-n5"} width="85" key={index} /> : null})}
                          {drop.sponsors_data.length > 3 &&
                            <span className="sponsor_cnt">+{drop.sponsors_data.length-3}</span>
                          }
                        </div>

                        <div className="multi_sponsor_detail mb-3">
                        {drop.sponsors_data.map((v,index)=>{ return (index < 3) ? <><a href={v.nft_collection_url} target="_blank" rel="noreferrer">{v.sponsor.name}</a>  <span>•</span></> : null})}

                          {drop.sponsors_data.length > 3 &&
                          <a href="#more">+more</a>
                          }
                        </div>
                      </div>
                    :
                      <SingleCollectionCard drop={drop} />
                    }

                    <div className="drop_details_area" style={{background: 'none'}}>
                      <p className="card-text d-flex align-items-center mb-2" style={{color: '#C7C8C9'}}><i className="ri-flag-line me-2" style={{color: '#C7C8C9'}} /> {drop.total_claimed} Claimed</p>
                      <p className="card-text d-flex align-items-center" style={{color: '#C7C8C9'}}><i className="ri-store-2-line me-2" style={{color: '#C7C8C9'}}/> Sponsored by  <a className='ps-1 sponsor-site' style={{color: '#C7C8C9', textDecoration: 'none'}}>{drop.sponsored_display_name}</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 py-3 bannerimg d-flex align-items-center">
              <img src={drop.banner_images[0]} className="img-fluid" alt="tshirt" />
            </div>
          </div>
        </Link>
        :
        <Link to={{pathname: `/previous-drop/${drop.url_handle}`, dropdata: drop}} className="card previous_drop_mobile_card my-4">
          <div className="card-top text-center">
            <img src={drop.banner_images[0]} className="img-fluids" alt="tshirt" width="200"/>
          </div>
          <div className="card-body">
            <h4 className="title">{drop.banner_label}</h4>
            {drop.sponsors_data.length > 1 &&
              <h4 className="subtitle mt-3">{drop.nft_name}</h4>
            }

            <div className="card previous-collection-card my-3">
              <div className="row g-3 align-items-center">
              
              {drop.sponsors_data.length > 1 ? 
                      <div className="mt-4">
                        <div className="multi_sponsors mb-3 cursor-pointer">
                          {drop.sponsors_data.map((v,index)=>{ return (index < 3) ? <img src={v.sponsor.logo_url} alt={v.sponsor.name} className={index == 0 ? "img-fluid rounded-circle" : "img-fluid rounded-circle ms-n5"} width="85" key={index} /> : null})}
                          {drop.sponsors_data.length > 3 &&
                            <span className="sponsor_cnt">+{drop.sponsors_data.length-3}</span>
                          }
                        </div>

                        <div className="multi_sponsor_detail mb-3">
                        {drop.sponsors_data.map((v,index)=>{ return (index < 3) ? <><a href={v.nft_collection_url} target="_blank" rel="noreferrer">{v.sponsor.name}</a>  <span>•</span></> : null})}

                          {drop.sponsors_data.length > 3 &&
                          <a href="#more">+more</a>
                          }
                        </div>
                      </div>
                    :
                      <SingleCollectionCard drop={drop} />
                    }
              </div>
            </div>

            <div className="drop_details_area" style={{background: 'none'}}>
              <p className="card-text d-flex align-items-center mb-2" style={{color: '#C7C8C9'}}><i className="ri-flag-line me-2" style={{color: '#C7C8C9'}} /> {drop.total_claimed} Claimed</p>
              <p className="card-text d-flex align-items-center" style={{color: '#C7C8C9'}}><i className="ri-store-2-line me-2" style={{color: '#C7C8C9'}}/> Sponsored by  <a className='ps-1 sponsor-site' style={{color: '#C7C8C9'}}> {drop.sponsored_display_name}</a></p>
            </div>
          </div>
          <div className="date_aside"><p>{showUTCTime3(drop.drp_date_time_utc)}</p></div>
        </Link>
      }
    </>
  )
}