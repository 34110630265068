import React,{useState, useEffect} from 'react'
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import SponsorCard from '../../components/SponsorCard'
import PreviousSingleSponsor from './single_sponsor';
import SingleCollectionCard from '../../components/Collectioncard/single';
import PreviousMultiSponsor from './multi_sponsors';

import {subscribeEmail} from "../../services/actions";
import { PreviousDropsBox } from '../../components/px-drops/previous_drop';
import MultiSponsorList from '../../components/MultiSponsor/list';
import { showUTCTime2 } from '../../utils';
import { goToBack } from '../../services/actions';

const PreviousDropDetail = (props) => {
  const [sponsorList, setSponsorList] = useState(false);
  const dropdata = props.location.dropdata;

  const actualDrop = dropdata ; //? dropdata : activeDrop.data;
  
  useEffect(() => {
    props.goToBack(()=>{ 
      props.history.goBack();
      props.goToBack(null);
    });
  }, [sponsorList])

  return (
    <>
      { 
      !sponsorList ?
      <div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 mt-4">
              <div className="row align-items-center">
                <div className="col-md-4 text-center">
                  <img src={actualDrop.banner_images[0]} alt={actualDrop.nft_name} className="img-fluid" />
                </div>
                <div className="col-md-8 pt-3 pt-md-0 pt-lg-0">
                  <h3 className="heading_lg" style={{color: '#ECE75B'}}>{actualDrop.banner_label}</h3>
                  <p style={{color: '#C7C8C9'}}>
                    <span>{showUTCTime2(actualDrop.drp_date_time_utc)}</span>
                    <span className="px-2">•</span>
                    <span><i className="ri-flag-line" style={{color: '#C7C8C9'}} /> {actualDrop.total_claimed} Claimed</span>
                  </p>
                  {
                    actualDrop.sponsors_data.length > 1 ?
                    <PreviousMultiSponsor {...props} actualDrop={actualDrop} setSponsorList={setSponsorList} />
                    :
                    <SingleCollectionCard drop={actualDrop} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-4'>
          <div className={"container mb-5"}>
            <div className={"row justify-content-center"}>
              <div className={"col-lg-8"}>
                <div className="card sponsor-card" >
                  <div className="card-body">
                    <h5 className="card-title">
                      <div className="d-flex justify-content-start align-items-center">
                        <img src={actualDrop.sponsor_logo_url} className="me-2 rounded-circle" height="45" width="45" alt="" />
                        <span>
                          About the Sponsor  <a href={actualDrop.sponsor_external_link} className="text-white" rel="noreferrer" target="_blank">{actualDrop.sponsored_display_name}</a>
                        </span>
                      </div>
                    </h5>
                    <p className="card-text">{actualDrop.about_sponsor}</p>
                    {/* actualDrop.sponsor_external_link */}
                    <a href={"https://polyientx.com/sponsor-pxdrops"} target='_blank' className="gradient_txt">Become a Drop Sponsor <i className="ri-external-link-line"></i></a>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

      :
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 mt-4">
            <MultiSponsorList {...props} actualDrop={actualDrop} sponsorList={setSponsorList} modaldisplay={true} />
          </div>
        </div>
      </div>
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  dropDuration:state.dropDuration
})

const mapDispatchToProps = {
  subscribeEmail,
  goToBack
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousDropDetail)
