import React from 'react';
import Collectioncard from '../../components/Collectioncard';
import RewardsBox from '../../components/RewardsBox';

const PostClaim = (props) => {
  const {user,activeDrop} = props;

  return <>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 mt-md-5">
          {
            props.page.width >= 768 &&
            <>
              <h1 className="text-white heading">Rewards for NFT Communities</h1>
              <h2 className="text-white heading my-4" style={{fontSize: '24px'}}>We reward NFT communities with merch, digital goods and more.</h2>
              <RewardsBox {...props} />
            </>
          } 
          { false &&
          <div className="text-white border-top border-light py-3 ">
            <p className="card-text d-flex align-items-center"><i className="ri-flag-line me-2" /> {activeDrop.data.available_drops} Available</p>
          </div>
          }
        </div>
      </div>
    </div>
  </>;
};

export default PostClaim;