import React, {useState,useEffect,useRef} from 'react';
import { connect } from 'react-redux';
import {ConnectClaimStep} from '../../components/ConnectStep';
import TimerClaim from '../../components/Tshirt/timer_claim';
import Field from '../../components/UI/Fields';
import Pxdrops from '../../components/px-drops';

import countries from '../../services/constants/countries.json';
import moment from "moment";

import {claimForDrop,blockClaimSlot,setFormSubmitFunction} from "../../services/actions";
import {getMyTokenIds} from "../../utils/ethereum";

import {ValidateEmail} from "../../utils/index";

function Address(props) {
    const {activeDrop,user} = props;
    let base_url ="/drop/"+activeDrop.data.url_handle;

    let inputEl = useRef();
    const [errorObj, setErrorObj] = useState({});
    const [tokenIds, setTokenIds] = useState([]);
    const [formInfo, setFormInfo] = useState({});
   
    
    useEffect(async()=>{
      // set form
      let formFields ={}
      let requiredFields ={}
      for (let index = 0; index < activeDrop.data.form_template.form_fields.length; index++) {
        const element = activeDrop.data.form_template.form_fields[index];
        if(element.field_required){
          requiredFields[element.field_name] = "";
        }
        formFields[element.field_name] = "";
      }
      console.log("requiredFields",requiredFields);
      console.log("formFields",formFields);
      setErrorObj(requiredFields);
      setFormInfo(formFields);
      // end form set

      const data = {eth_address:user.data.eth_address,drop_id:activeDrop.data.id,nft_address:user.dropNft.nftAddr,nft_token_id:user.dropNft.tokenId,form_info:null}
      props.blockClaimSlot(data,(result)=>{
         if(result.type=="error"){
            alert(result.message.join(", "));
            props.history.push(base_url);
         }else{
            if(result.claim && result.claim.status ==true){
              props.history.push('/delivery');
            }

            if(result.claim && moment(result.claim.claimExpireDate).utc() <= moment().utc()){
              props.history.push(base_url);
            }
         }
      })
      
      // no need for this 2nd drop
      // let ids = await getMyTokenIds(activeDrop.data,user.data.eth_address);
      // setTokenIds(ids);

      return ()=>{
        // document.getElementById('claim_form').onclick = null;
        props.setFormSubmitFunction(()=>{});
      }
    },[])

    const setValue = (key,value)=>{
      // let value =e.target.value;
      console.log("setValue",key,value);
      let f = {...formInfo};
      f[key] = value;
      setFormInfo(f);
    }

    const validateInputs = ()=>{
      let flag = true;
       let f = {...formInfo};
       let error = {};
        for (const key in errorObj) {
          error[key] = "";
        }
        

       for (const key in f) {
         if(Array.isArray(formInfo[key])){
          if(errorObj.hasOwnProperty(key)  && formInfo[key].length == 0){
            flag = false;
            error[key] ='It\s required';
          }
         }else{
          if(errorObj.hasOwnProperty(key)  && formInfo[key].trim() == ""){
            flag = false;
            error[key] ='It\s required';
          }
         }
        
        if(key.toLowerCase().includes('email') && !ValidateEmail(formInfo[key])){
          flag = false;
          error[key] ='Please enter a valid email address';
        }
       }
       setErrorObj(error);
       return flag;
    }

    const submitForm = ()=>{
      let flag = validateInputs();
      let f = {...formInfo};
      console.log("formInfo",f);
      const data = {eth_address:user.data.eth_address,drop_id:activeDrop.data.id,nft_address:user.dropNft.nftAddr,nft_token_id:user.dropNft.tokenId,form_info:f}

  
      if(flag){
        document.getElementById('claim_form').style.disabled = true;
        props.claimForDrop(data,(result)=>{
          document.getElementById('claim_form').style.disabled = false;
           if(result.type =='success'){
              props.history.push('/delivery');
           }else{
             alert(result.message.join(", "))
           }
           
        })
      }
    }

    props.setFormSubmitFunction(submitForm);

    const chkClassName =(baseClass, key)=>{
      console.log(errorObj,"errorObj[key]",errorObj[key],"key ",key);
      return !errorObj.hasOwnProperty(key) || errorObj[key]=="" ? `${baseClass}` : `${baseClass} form-control-error`;
    }
    
    let title = "You’re Almost Done..."
    let src = "/assets/images/pug-rounded.png"
    return (
      <>
     
        <div className="container pt-4">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <ConnectClaimStep step_number="2" title={title} src= {src} {...props} checked={false}/>

              <h3 className="pt-4 heading_h4" style={{lineHeight: '30px'}}>We'll hold your reward for a few minutes to give you time to complete the following delivery information:</h3>


              <div className="address-form mt-5">
                <p className="d-flex align-items-start"><i className="ri-lock-line ri-midx me-1"></i> {activeDrop.data.claim_form_notes} For your security, we will order your Nano S Wallet from Ledger and they will ship to you directly from the manufacturer. </p>
                <form>

                 {activeDrop.data.form_template.form_fields.map((field,index)=>{
                   return <Field key={index} info={field} cssName={chkClassName}  value={formInfo[field.field_name]} changeEvent={setValue}/>
                 })}  
                   
                </form>
              </div>
               
            </div>
          </div>
        </div>
      </>
    )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
  claimForDrop,
  blockClaimSlot,
  setFormSubmitFunction
}

export default connect(mapStateToProps, mapDispatchToProps)(Address)
