import React, {useState} from 'react'
import { connect } from 'react-redux'

import {ConnectStep} from '../../components/ConnectStep'
import Share from '../../components/Share'
import Pxdrops from '../../components/px-drops'
import Recommend from '../../components/Recommend'
import WinTicket from '../../components/WinTicket';
import MultiSponsorList from '../../components/MultiSponsor/list';

function NotRegisteredStep2(props) {
  const {activeDrop,page} = props;
  const [displayMultiSponsor, setDisplayMultiSponsor] = useState(false);
    return (
      <>
        {
          !displayMultiSponsor ?
          <div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="no-sweet mt-5 mb-4">
                  <h3 className='heading_lg'>You're Not Eligible to Claim</h3>
                  <h3 className='heading_md my-4' style={{color: '#ECE75B'}}>We didn't find an eligible NFT in your  wallet but you can still...</h3>

                  <h5 style={{fontFamily: 'BeatriceRegular', fontSize: '16px'}} className="text-white">Sorry you missed out</h5>
                  <h4 className='text-white'>But wait, there's more...</h4>
                </div>
              </div>
            </div>
          </div>

          <WinTicket {...props} close={true} setDisplayMultiSponsor={setDisplayMultiSponsor} hide_sponsor={true} />
          </div>
          :
          <MultiSponsorList {...props} actualDrop={activeDrop.data} sponsorList={setDisplayMultiSponsor} from_opensea="true" />
        }

      </>
    )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NotRegisteredStep2)
