import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom';
import {Navbar,Container,Nav} from 'react-bootstrap'

import Toast from '../Toast';

import {userLogin,userReset} from "../../services/actions"
import { withRouter } from 'react-router-dom';


import {subscribeEmail, goToBack, resetBack} from "../../services/actions";

import {ValidateEmail} from "../../utils/index"
import {shortEthAddress} from "../../utils/ethereum"

export const Header = withRouter(props => {
  const {user, backButton,activeDrop} = props;
  const [email,setEmail] = useState('');
  const [error,setError] = useState('');
  const [success,setSuccess] = useState('');
  const [expand,setExpand] = useState(false);
  // console.log("location.pathname",props.location)
  const subscribe= ()=>{
  setError('')
  setSuccess('')
  let data = {sub_type:"drops",email:email}
    if(ValidateEmail(email)){
      props.subscribeEmail(data,(result)=>{
        if(result.type =='error'){
          setError(result.message.join(","));
        }else{
          setEmail('');
          setSuccess(result.message.join(","));
        }
      })
    }else{
      setError('Invalid email. Please try again.')
    }
  }


  const setValue=(value)=>{
    value = value.trim();
    setEmail(value);
    setError('')
    setSuccess('')
  }


  const handleClick = (type) => {
    props.userLogin(type);
  };
  
  const wheel = function(event){
    return;
    if(window.scrollY > 100) {
      // we are going down
      if (document.getElementById("topheader") !== null) {
        document.getElementById("topheader").style.marginTop = "-55px";
      }
      if (document.getElementById("droptimer") !== null) {
        document.getElementById("droptimer").style.marginTop = "-70px";
      }
      if (document.getElementById("timerbanner") !== null) {
        document.getElementById("timerbanner").style.marginTop = "-70px";
      }
    }else {
      // we are going up
      if (document.getElementById("topheader") !== null) {
        document.getElementById("topheader").style.marginTop = "0";
      }
      if (document.getElementById("droptimer") !== null) {
        document.getElementById("droptimer").style.marginTop = "0";
      }
      if (document.getElementById("timerbanner") !== null) {
        document.getElementById("timerbanner").style.marginTop = "0";
      }
    }
  }

  useEffect(()=>{
    window.addEventListener("scroll", wheel);
    return ()=>{
      window.removeEventListener("scroll", wheel);
    }
   
  },[])
  // go to back to previous page
  const goBack = () => {
    if(props.backButton!=null){
      props.backButton();
    }
    
  };

  function handleNavMenu(){
    setExpand(!expand);
  }

  useEffect(() => {
    if (!expand) {
      if (document.getElementsByClassName('outer_content_area')[0] != null) {
        document.getElementsByClassName('outer_content_area')[0].style.display = 'block';
      }
      if (document.getElementsByClassName('prev_sponsor_content')[0] != null) {
        document.getElementsByClassName('prev_sponsor_content')[0].style.display = 'block';
      }
      if (document.getElementsByClassName('register')[0] != null) {
        document.getElementsByClassName('register')[0].style.display = 'block';
      }
    } else {
      if (document.getElementsByClassName('outer_content_area')[0] != null) {
        document.getElementsByClassName('outer_content_area')[0].style.display = 'none';
      }
      if (document.getElementsByClassName('prev_sponsor_content')[0] != null) {
        document.getElementsByClassName('prev_sponsor_content')[0].style.display = 'none';
        if(props.page.width >= 768) {
          document.getElementsByClassName('navbar-dark')[0].style.position = 'unset';
        }
      }
      if (document.getElementsByClassName('register')[0] != null) {
        document.getElementsByClassName('register')[0].style.display = 'none';
      }
    }
    props.goToBack(null);
  }, [expand]);
 
  return (
    <>
      <div className="nft-dropx">
        <Navbar bg="light" id="topheader" expand={false} expanded={expand} className="navbar-dark">
          <div className="nav_logo">
            <Link className="nav-link" to={"/drop/"+activeDrop.data.url_handle}>
              {
                props.page.width < 768 &&
                <img src="/assets/images/drops.svg" alt="" />
              }
            </Link>
            <div className="mobile-side-head">
              {user.type =='success' && 
                <a href="#" className="text-white text-decoration-none"><img src="/assets/images/profile_img.png" width="30" alt="profile" /> {/*shortEthAddress(user.data.eth_address)*/}</a>
              }
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-3" onClick={()=> handleNavMenu() } />
            </div>
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Container>
              <Nav className={ props.nav_align ? 'ms-auto' : "mx-auto"}>
                <Link className="nav-link" to={"/drop/"+activeDrop.data.url_handle} onClick={()=>{setExpand(!expand)}}>Home</Link>
                <Link className="nav-link" to="/previous-drops" onClick={()=>{setExpand(!expand)}}>Previous Drops</Link>
                <Nav.Link href="https://polyientx.com" target="_blank" className='nav-link polyientx-site' onClick={()=>{setExpand(!expand)}}>Visit PolyientX</Nav.Link>
                <Nav.Link className="nav-link" href="https://polyientx.com/sponsor-pxdrops" target="_blank" style={{border: '0px solid'}}>Sponsor a Drop</Nav.Link>

                {false && 
                  <Nav.Link className="nav-link desktop-only" href="#link">
                    <img src="/assets/images/profile_img.png" width="30" alt="profile" /> {shortEthAddress(user.data.eth_address)}
                  </Nav.Link>
                }
              </Nav>
            </Container>
            <div className='navbar-form'>
              <div className="next-drop mt-4 py-4 bg-theme-purple">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 ">
                      <h4 className="heading_h3 text-white">Get updates on upcoming Drops!</h4>
                    
                      <div className="input-group my-3">
                        <span className="input-group-text" id="inputGroupPrepend"><i className="ri-mail-line" style={{color: '#FFFFFF'}} /></span>
                        <input value={email}  type="email" className={error=="" ? "form-control" : "form-control form-control-error" } placeholder="Enter Your Email Address" aria-describedby="button-addon2"  onChange={(e)=>{setValue(e.target.value)}} />
                        <button className="btn" type="button" id="button-addon2" onClick={subscribe}>Submit</button>
                      </div>
                    </div>
                  </div>
                    {success != "" &&
                      <p className="form-text text-white">
                        <i class="ri-checkbox-circle-line"></i> {success}
                      </p>
                    }
                    {error != "" &&
                      <p className="form-text text-white">
                        <i class="ri-close-circle-line"></i> {error}
                      </p>
                    }
                </div>
              </div>

              <div className="desktop-onlys top_footer">
                <div className="container mt-4">
                  <h3 className="heading_h3">Connect with PolyientX</h3>
                  <div className="mb-4">
                    <a href="https://twitter.com/PolyientX" target="_blank" rel="noreferrer" className="text-decoration-none"><i className="ri-twitter-line ri-2x" /></a>
                    {/* <i className="ri-discord-line ri-2x" /> */}
                  </div>

                  <div className="row justify-space-between">
                    <div className="col-md-6">
                      <a href="https://www.iubenda.com/terms-and-conditions/54937802" rel="noreferrer" target="_blank">Terms of Service</a>
                    </div>
                    <div className="col-md-6 text-lg-end pe-4">
                      <a href="https://www.iubenda.com/privacy-policy/54937802" rel="noreferrer" target="_blank">Privacy Policy</a>
                    </div>
                    <div className="col-md-12 text-center">
                      <h6 className="mt-0 mb-5">©PolyientX, 2022</h6>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </Navbar.Collapse>
        </Navbar>
        {
          props.page.width >= 768 &&
          <div className="d-flex justify-content-around">
            <div className="pt-3">
              {
                backButton &&
                <span className="prev_link text-primary cursor-pointer" onClick={() => goBack()}><i className="ri-arrow-left-line text-primary me-2"></i> Back</span>
              }
            </div>
            <div style={{marginLeft: (backButton) ? '-5rem' : '0px' }}>
              <Link to={"/drop/"+activeDrop.data.url_handle} className="text-decoration-none"><div className="text-center">
                <img src="/assets/images/drops.svg" alt="" />
                <p className='footer-title mt-3'>BROUGHT TO YOU BY <img src="/assets/images/poliyent-disabled.png" alt="PolyientX" className="ms-3" /></p>
              </div></Link>
            </div>
            <div className=""></div>
          </div>
        }
      </div>
    </>
  )
})

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  backButton: state.backButton,
  activeDrop:state.activeDrop
})

const mapDispatchToProps = {
  userLogin,
  userReset,
  subscribeEmail,
  goToBack
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
