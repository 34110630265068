import React,{useEffect} from "react";
import {Route,BrowserRouter, Redirect} from "react-router-dom";
import { useLocation,withRouter} from 'react-router-dom'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {LayoutOne, LayoutTwo , LayoutThree, LayoutPreviousDropDetail} from "../layouts"
import Home from "../pages/Home"
import Error from "../pages/Error"
import Sponsor from "../pages/Sponsor";
import Register from "../pages/Register";
import Connect from "../pages/Register/Connect";
import Complete from "../pages/Register/Complete";
import Notqualified from "../pages/Register/notqualified";
import Claim from "../pages/Register/Claim";
import ClaimDay from "../pages/Claimday";
import ClaimStepTwo from "../pages/Claimday/ClaimStepTwo";
import Claimtshirt from "../pages/Claimday/Claimtshirt";
import Address from "../pages/Claimday/Address";
import Delivery from "../pages/Claimday/Delivery";
import NotRegisteredStep1 from "../pages/Claimday/not_registered_step_1";
import NotRegisteredStep2 from "../pages/Claimday/not_registered_step_2";
import ClaimDisqualified from "../pages/Claimday/ClaimDisqualified";

import {getActiveDrop,setPageArea,setActiveDropDuration} from "../services/actions"
import RegistrationClosed from "../pages/Claimday/closed";
import RegistrationMissed from "../pages/Claimday/missed";
import NotRegistered from "../pages/Register/not_registered";
import PreviousDrops from "../pages/PreviousDrops/index";
import PreviousDropDetail from "../pages/PreviousDrops/detail";

if (process.env.REACT_APP_ETH_ENV == "production" && window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}


function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} page={rest.page} activeDrop={rest.activeDrop} setActiveDropDuration={rest.setActiveDropDuration} dropDuration={rest.dropDuration}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export const Routes = (props) => {
  useEffect(()=>{
    props.getActiveDrop();
  },[])
  useEffect(()=>{
    props.setPageArea();
    window.onresize = props.setPageArea;
    return ()=>{
      window.onresize = null;
    }
  },[window.innerWidth]);

  const {user,activeDrop} = props
  return (
    <BrowserRouter>
    <ScrollToTop>
      <>
      {activeDrop.type=='success' ?
       <>
        {/* <RouteWrapper exact path="/" component={Home} layout={LayoutOne} {...props}/> */}
        <RouteWrapper exact path="/drop/:handle" component={Home} layout={LayoutOne} {...props}/>

        <RouteWrapper exact path="/register" component={Register} layout={LayoutOne} {...props}/>
        <RouteWrapper exact path="/connect" component={Connect} layout={LayoutOne} {...props}/>
        <RouteWrapper exact path="/complete" component={Complete} layout={LayoutOne} {...props}/>
        <RouteWrapper exact path="/user-registered" component={NotRegistered} layout={LayoutOne} {...props}/>
        <RouteWrapper exact path="/sponsor" component={Sponsor} layout={LayoutTwo} {...props}/>
        <RouteWrapper exact path="/previous-drops" component={PreviousDrops} layout={LayoutTwo} {...props}/>
        <RouteWrapper exact path="/previous-drop/:id" component={PreviousDropDetail} layout={LayoutTwo} {...props}/>
        <RouteWrapper exact path="/notqualified" component={Notqualified} layout={LayoutOne} {...props}/>

        <RouteWrapper exact path="/claim-connect" component={ClaimStepTwo} layout={LayoutThree} {...props}/>
        <RouteWrapper exact path="/address" component={Address} layout={LayoutThree} {...props}/>
        <RouteWrapper exact path="/delivery" component={Delivery} layout={LayoutOne} {...props}/>
        <RouteWrapper exact path="/claim-disqualified" component={ClaimDisqualified} layout={LayoutOne} {...props}/>
        <RouteWrapper exact path="/not-registered" component={NotRegisteredStep2} layout={LayoutOne} {...props}/>

        
        {/* <RouteWrapper exact path="/claim" component={Claim} layout={LayoutOne} {...props}/>     */}
        {/* <RouteWrapper exact path="/claim-step1" component={ClaimDay} layout={LayoutThree} {...props}/> */}
        {/* <RouteWrapper exact path="/claimtshirt" component={Claimtshirt} layout={LayoutThree} {...props}/> */}

        
        
        {/* <RouteWrapper exact path="/registration-closed-3hour-before" component={RegistrationClosed} layout={LayoutThree} {...props}/> */}
        <RouteWrapper exact path="/registration-missed-out-sold-out" component={RegistrationMissed} layout={LayoutThree} {...props}/>
        <RouteWrapper exact path="/claim-not-registered-step-1-no-used" component={NotRegisteredStep1} layout={LayoutThree} {...props}/>
        
        <RouteWrapper exact path="/error/404" component={Error} layout={LayoutOne} {...props}/>
        <Redirect to={"/drop/"+activeDrop.data.url_handle}/>
      </>
      : <Redirect to="/"/>}
      </>
      </ScrollToTop>
    </BrowserRouter>
  )
}

Routes.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
  getActiveDrop,
  setPageArea,
  setActiveDropDuration
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
