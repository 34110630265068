// for making helper functions

import moment from "moment";


export function ValidateEmail(email) 
{
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}



 export async function   copyText(text){
  await window.navigator.clipboard.writeText(text); 
 }



 export function showUTCTime(date_time){
    //   Fri, December 10th 15:00
    return  moment.utc(date_time).format('ddd, MMMM Do HH:mm');
 }

 export function showUTCTime2(date_time){
    //   December 10th, 2022
    return  moment.utc(date_time).format('MMMM Do, YYYY');
 }

 export function showUTCTime3(date_time){
    //   Friday 11/10
    return  moment.utc(date_time).format('LL');
  }
 

 export function addToCalendar(drop){
     let url =`https://calendar.google.com/calendar/render?action=TEMPLATE&text=PX Drop - ${encodeURI(drop.dropName)}&dates=${encodeURI(drop.drp_date_time_utc.replace(/-/g, '').replace(/:/g, ''))}/${encodeURI(drop.drp_date_time_utc.replace(/-/g, '').replace(/:/g, ''))}&details=${encodeURI(drop.about_sponsor)}`;
    //  let url =`https://calendar.google.com/calendar/render?action=TEMPLATE&text=PX Drop&dates=${encodeURI(drop.drp_date_time_utc)}/${encodeURI(drop.drp_date_time_utc)}&details=With%20clowns%20and%20stuff&location=North%20Pole`
     window.open(url);
 }


export const GPageView = (title,path)=>{
    if(window.gtag){ 
      window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
        'page_title' : title,
        'page_path': path
      });
    }
  }
  
  export const GPageEvent = (name,label,category,)=>{
    if(window.gtag){ 
      window.gtag('event', name, {
        'event_label': label,
        'event_category': category,
        'non_interaction': true
      }); 
    }
  }
  
  
  export const GScreenView = (screen_name)=>{
    if(window.gtag){ 
      window.gtag('event', 'screen_view', {
        'app_name': 'Ape Island Season 2',
        'screen_name' : screen_name
      });
    }
  }