import React from 'react'

const RewardsBox = (props) => {
  return (
    <div className="feedback_box mt-4 mb-5">
      {/* <h3>What types of rewards are you most interested in?</h3> */}
      <div className="row mt-3">
        <div className="col-md-3 col-6">
          <div className="card mt-3">
            <div className="py-3">
              <div><i className="ri-t-shirt-line ri-2x gradient_txt"></i></div>
              <div>Merch</div>
            </div>
          </div>
        </div>
        
        <div className="col-md-3 col-6">
          <div className="card mt-3">
            <div className="py-3">
              <div><i className="ri-gift-2-line ri-2x gradient_txt"></i></div>
              <div>Digital Goods</div>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-6">
          <div className="card mt-3">
            <div className="py-3">
              <div><i className="ri-price-tag-3-line ri-2x gradient_txt"></i></div>
              <div>Discounts</div>
            </div>
          </div>
        </div>

        <div className="col-md-3 col-6">
          <div className="card mt-3">
            <div className="py-3">
              <div><i className="ri-ticket-line ri-2x gradient_txt"></i></div>
              <div>Tickets</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RewardsBox;