import React from 'react'
import { connect } from 'react-redux'

import Collectioncard from '../../components/Collectioncard';
import {ConnectStep} from '../../components/ConnectStep';
import Instructions from '../../components/instructions';
import SponsorCard from '../../components/SponsorCard';
import Pxdrops from '../../components/px-drops';

function Claimtshirt(props) {
    let title = "Congrats! Now claim your Tshirt"
    let src = "/assets/images/pug-rounded.png"
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <ConnectStep step_number="2" title={title} src={src} {...props}/>

                        <h1 className="exclusive-drop pt-4">An exclusive drop for those holding a frog NFT from: </h1>
                        <div className="notregistered-collection">
                            <Collectioncard {...props}/>
                        </div>
                        <hr className='mt-4'/>
                        
                        <Instructions/>
                        
                        <div className="mt-5">
                        <div className="card sponsor-card">
                            <div className="card-body">
                            <h5 className="card-title">
                            <img src="/assets/images/Mask Group.png" className="ml-2" height="22" width="22" alt="" /> Sponsored By Croakz 
                            </h5>
                            <p className="card-text pt-4">Placeholder text about the sponsor. We should allow for four or five lines of description here.</p>
                            <a href="#a" className="sponsor-site btn">Learn more about CROAKZ by McGill!</a>
                            </div>
                        </div>
                        </div>
                        
                        <div className="row justify-content-center">
                            <div className="col-lg-11 nft-drops pt-5">
                                <Pxdrops/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => ({
    user: state.user,
    page: state.page,
    activeDrop:state.activeDrop,
    dropDuration:state.dropDuration,
  })
  
  const mapDispatchToProps = {
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Claimtshirt);