import React, {useState} from 'react';
import { connect } from 'react-redux';
import MultiSponsorList from '../MultiSponsor/list';
import {goToBack} from "../../services/actions";

const WinTicket = (props) => {
  const {activeDrop,close,page, setDisplayMultiSponsor} = props;
  const [sponsorList, setSponsorList] = useState(false);

  function jumpToShowList(){
    setDisplayMultiSponsor(true);
    
    props.goToBack(()=>{setDisplayMultiSponsor(false); props.goToBack(null); });
  }

  return <div className={page.width < 768 ? "" : "container mb-5"}>
    <div className={page.width < 768 ? "" : "row justify-content-center"}>
      <div className={page.width < 768 ? "" : "col-lg-10"}>
        <div className="feedback-mobile mb-3">
          <div className="bgbox" style={{backgroundColor: '#3C3044'}}>
            <div className="row">
              <div className="col-9">
                <h3 style={{color: '#ECE75B'}}>Win a Ticket to NFT.NYC 2022</h3>
                <p className="mb-4">Take a survey for a chance to win a ticket to this exclusive event on June 20.</p>
                <a href="https://form.typeform.com/to/qj2xdzpP" target='_blank' className='giveaway-survey'>Enter to Win <i className="ri-external-link-line"></i></a>
              </div>
              <div className="col-3">
                <img src="/assets/images/nft_admission.png" alt="NFT 2022" width={140} className="ticket_img" />
              </div>
            </div>
          </div>
        </div>
        {!props.hide_sponsor &&
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="shop my-4">
                  <h4>Shop on Opensea</h4>
                  <p>Feeling the FOMO? Join the Drop by purchasing a qualifying NFT.</p>
                  <div className="nft-drops">
                    {activeDrop.data.sponsors_data.length > 1 ? 
                    <span style={{cursor: 'pointer'}} className="nft-project-site-1 learn-more" onClick={() => jumpToShowList()}>Shop on Opensea <i className="ri-external-link-line" /></span>
                    :
                    <a href={activeDrop.data.sponsors_data[0].nft_collection_url} target='_blank' className="nft-project-site-1 learn-more">Shop on Opensea <i className="ri-external-link-line" /></a>
                    }
                  </div>
                </div>
              </div>
            </div>
            {
              sponsorList &&
              <div>
                <MultiSponsorList {...props} actualDrop={activeDrop.data} sponsorList={setSponsorList} from_opensea="true" /></div>
            }
          </div>
        }
      </div>
    </div>
  </div>;
};

const mapStateToProps = (state) => ({
  page: state.page,
  backButton: state.backButton,
})

const mapDispatchToProps = {
  goToBack
}

export default connect(mapStateToProps, mapDispatchToProps)(WinTicket);

