import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'


import {userLogin} from "../../services/actions"
import 'remixicon/fonts/remixicon.css';
import Header from '../../components/Header'
import Tshirt from '../../components/Tshirt';
import { Footer } from '../../components/Footer';
import Pxdrops from '../../components/px-drops';
import Recommend from '../../components/Recommend';

import Notify from '../../components/Notify'
import SponsorCard from '../../components/SponsorCard'
import Collectioncard from '../../components/Collectioncard'
import PreClaim from './pre_claim'
import PostClaim from './post_claim'
import RegistrationClosed from '../Claimday/closed'
import RegistrationMissed from '../Claimday/missed';
import MultiSponsorList from '../../components/MultiSponsor/list';

export const Home = (props) => {
  const {user,activeDrop,dropDuration} = props;
  const [displayMultiSponsor, setDisplayMultiSponsor] = useState(false);
 
  return (
    <>
      {
        !displayMultiSponsor ?
        <div>
          {(dropDuration > 0 || dropDuration ==="") ?
            <>
              <PreClaim {...props}/>
            </>
            :
            <>
              {activeDrop.data.available_drops == 0 ?
                <RegistrationMissed {...props} setDisplayMultiSponsor={setDisplayMultiSponsor}/>
              :
                <PostClaim {...props}/>
              }
            </>
            
          }
          <SponsorCard {...props} />
        </div>
        :
        <MultiSponsorList {...props} actualDrop={activeDrop.data} sponsorList={setDisplayMultiSponsor} from_opensea="true" />
      }
        
    </>
  )
}

Home.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user:state.user,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
  page: state.page
})

const mapDispatchToProps = {
  userLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
