import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { goToBack } from '../../services/actions';

const PreviousMultiSponsor = (props) => {
  const { page, activeDrop, actualDrop, setSponsorList } = props;

  
  return (
    <>
      <div className="d-flex align-items-start my-3">
        <div className="">
          <h5 className="heading_md" style={{color: '#C7C8C9'}}>An exclusive reward for</h5>
          <h4 className="heading_h3 mb-0">{actualDrop.nft_name}</h4>
        </div>
      </div>

      

      <div className="multi_sponsors mb-3 cursor-pointer" onClick={() => setSponsorList(true)}>
        { actualDrop.sponsors_data.map((v,index)=>{ return (index < 3) ? <img src={v.sponsor.logo_url} alt={v.sponsor.name} className={index == 0 ? "img-fluid rounded-circle" : " img-fluid rounded-circle ms-n5"} width="85" key={index} /> : null})}
        {actualDrop.sponsors_data.length > 3 &&
          <span className="sponsor_cnt">+{actualDrop.sponsors_data.length-3}</span>
        }
      </div>

      <div className="multi_sponsor_detail mb-3">
      {actualDrop.sponsors_data.map((v,index)=>{ return (index < 3) ? <><a href={v.nft_collection_url} target="_blank" rel="noreferrer">{v.sponsor.name}</a>  <span>•</span></> : null})}

        {actualDrop.sponsors_data.length > 3 &&
         <span class="text-decoration-underline cursor-pointer" onClick={() => setSponsorList(true)}>+more</span>
        }
      </div>
    </>
  )
}


const mapStateToProps = (state) => ({
  page: state.page,
  activeDrop: state.activeDrop,
})

const mapDispatchToProps = {
  goToBack
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviousMultiSponsor);

