import React from 'react'
import { connect } from 'react-redux';

import {ConnectStep} from '../../components/ConnectStep'
import Instructions from '../../components/instructions'
import Collectioncard from '../../components/Collectioncard'
import SponsorCard from '../../components/SponsorCard'
import Pxdrops from '../../components/px-drops'

import {showUTCTime,addToCalendar} from "../../utils/index"


function NotRegistered(props) {
  const {activeDrop,user} = props;
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 mb-3">
            <ConnectStep src={activeDrop.data.logo_url} step_number="4" title="You’re Registered! But You’re Not Done Yet..." gutters="2" {...props} />

            <h3 className="heading_h3" style={{color: '#ECE75B'}}>Come back on claim day for a chance to snag a reward before they’re gone!</h3>
          </div>
        </div>
      </div>

      <SponsorCard {...props}/>
      
      {/* <div className="container" hidden="hidden">
        <div className="row justify-content-center">
          <div className="col-lg-10 ">
            <div className="text-white mt-4">
              <p className="card-text d-flex align-items-center"><i className="ri-time-line me-2" /><span className='text-decoration-underline' onClick={()=>{addToCalendar(activeDrop.data)}} style={{cursor:"pointer"}}>  {showUTCTime(activeDrop.data.drp_date_time_utc)}</span><span className="ps-2" style={{color: "#00D1DE"}}>UTC</span></p>
              <p className="card-text d-flex align-items-center"><i className="ri-flag-line me-2" />{activeDrop.data.available_drops}  Available</p>
              <p className="card-text d-flex align-items-center"><i className="ri-store-line me-2" /> Sponsored by  {activeDrop.data.sponsored_display_name}</p>
            </div>
            <br/>
          </div>
        </div>
      </div> */}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.activeDrop,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NotRegistered)

