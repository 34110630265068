import { combineReducers } from 'redux';

import {activeDropReducer, pageAreaReducer, setActiveDropDuration, setFormFun, previousDropsReducer, setBackbutton} from './list';
import {userReducer} from './user';

const rootReducer = combineReducers({
    page:pageAreaReducer,
    activeDrop:activeDropReducer,
    dropDuration:setActiveDropDuration,
    claimFormSubmit:setFormFun,
    user:userReducer,
    previousDrops:previousDropsReducer,
    backButton:setBackbutton
});

export default rootReducer;