import React from 'react';
import Collectioncard from '../../components/Collectioncard';
import HowToClaim from '../../components/HowToClaim';
import RewardsBox from '../../components/RewardsBox';
import {showUTCTime,addToCalendar} from "../../utils/index"

const PreClaim = (props) => {
  const {user,activeDrop,page} = props;

  return <>
    {  true ?
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 mt-5">
            {props.page.width >= 768 &&
              <div>
                <h1 className="text-white heading">Rewards for NFT Communities</h1>
                <p className='heading_h4'>We reward NFT communities with merch, digital goods and more. </p>
                <RewardsBox {...props} />
              </div>
            }
          
            <HowToClaim {...props}/>
            
          </div>
        </div>
      </div>
    </>
    :
    <div hidden="hidden">
    <h1 className="text-white heading">An exclusive drop for those holding an NFT from</h1>
    <Collectioncard  {...props}/>

    <div className="text-white border-top border-light py-3 ">
      <p className="card-text d-flex align-items-center"><i className="ri-time-line me-2" /><span className='text-decoration-underline' onClick={()=>{addToCalendar(activeDrop.data)}} style={{cursor:"pointer"}}> {showUTCTime(activeDrop.data.drp_date_time_utc)}</span><span className="ps-2" style={{color: "#00D1DE"}}>UTC</span></p>
      <p className="card-text d-flex align-items-center"><i className="ri-flag-line me-2" /> {activeDrop.data.available_drops} Available</p>
      <p className="card-text d-flex align-items-center"><i className="ri-store-2-line me-2" /> Sponsored by  <a href={activeDrop.data.sponsor_external_link} target="_blank" className='text-white ms-2'>{activeDrop.data.dropName}</a> </p>
    </div>
    </div>
    }
    

    

  </>;
};

export default PreClaim;