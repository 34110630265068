import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom';

import moment from 'moment';
const TimerClaim = (props) => {
  const {activeDrop,user} = props;

  const [dropDuration, setDuration] = useState(0);
  useEffect(()=>{
    if(user.claim && moment(user.claim.claimExpireDate).utc() >= moment().utc()){ 
      var eventTime= moment.utc(user.claim.claimExpireDate).unix(); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
      var currentTime = moment().utc().unix(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
      var diffTime = eventTime - currentTime;
      var duration = moment.duration(diffTime*1000, 'milliseconds');
      var interval = 1000;
  
      let timeInterval = setInterval(function(){
        duration = moment.duration(duration - interval, 'milliseconds');
        if(duration > 0 ){
          setDuration(duration)
        }else{
          window.location.reload();
          setDuration(0)
        }
      }, interval);

      return ()=>{
        clearInterval(timeInterval);
      }
    }
  },[user.claim])


  return (
    <>
    {dropDuration > 0 && 
     <div className="drop-timer" id="droptimer" style={{color:"#200D40"}}>
          <h5 className="card-title text-center bg-primary-3"  style={{textTransform:"uppercase"}}>
            <i className="ri-timer-line me-1" style={{color:"#200D40"}}></i> Holding Reward for &nbsp; <strong>{dropDuration.hours() + ":" + dropDuration.minutes() + ":" + dropDuration.seconds()}</strong>
          </h5>
        </div>
  }
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TimerClaim)
