import React from 'react'
import { toast } from 'react-toastify';

import {copyText} from "../../utils/index"
// import '../../styles/_index.scss'

function Recommend(props) {
    const {activeDrop} = props;
    return (
        <div className=''>
            <div className="card recommend d-flex justify-content-center">
                <div className="card-body py-4 ">
                    <h5 className="card-title">Have a friend with an eligible {activeDrop.data.dropName}?</h5>
                    <div className="cardbtnbody"><a href="#" className="pxbtn text-white mt-3" onClick={()=>{copyText(window.location.origin);toast.info("Link copied!");}}><span className="px-3 py-4">Recommend this Drop</span></a></div>
                </div>
            </div>
        </div>
    )
}

export default Recommend
