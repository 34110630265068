import React from 'react'

export const SingleCollectionCard = (props) => {
  const {user,drop} = props;
  return (
    <>
      <div className="card previous-collection-card my-4">
        <div className="row g-3 align-items-center">
          <div className="col-3">
            <img src={drop.logo_url} className="img-fluid rounded-circle" alt="" />
          </div>
          <div className="col-8">
            <div className="card-body">
              <h5 style={{color: '#C4C4C4'}}>An exclusive reward for</h5>
              <a className="nft-project-site-1 align-items-center text-white header_ld text-decoration-underline" href={drop.sponsors_data[0].nft_collection_url} target="_blank" rel="noreferrer">{drop.nft_name}</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleCollectionCard
