import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

export async function registerUser(data){
  let user_obj = await axios.post('/api/v1/login', data);
  axios.defaults.headers={...axios.defaults.headers,'time_stamp':data.time_stamp,'signature':data.signature};
  return user_obj.data;
}

export function setPageArea(){
  return async dispatch => {
    dispatch({
      type: "PAGE_SET_AREA",
      data:{
        width: window.innerWidth,
        height: window.innerHeight
      }
    }) 
  }
}

export function setActiveDropDuration(duration){
  return async dispatch => {
    dispatch({
      type: "SET_DROP_DURATION",
      data:duration
    }) 
  }
}



export function getActiveDrop(){
  return async dispatch => {
    dispatch({
      type: "GET_DROP_REQUEST"
    })
    let object = await axios.get(`/api/v1/drop`)
    dispatch({
      type: 'GET_DROP_SUCCESS',
      data:object.data.data,
    })
  }
}

export function getPreviousDrops(){
  return async dispatch => {
    dispatch({
      type: "GET_PREVIOUS_DROPS_REQUEST",
      data: {},
    });
    let object = await axios.get(`/api/v1/previous_drops`)

    dispatch({
      type: 'GET_PREVIOUS_DROPS_SUCCESS',
      data: object.data.data,
    })
  }
}


export function subscribeEmail(data,callback){
  return async dispatch => {
    dispatch({
      type: "EMAIL_SUBSCRIBE"
    })

    let object = await axios.post('/api/v1/email_subscribe', data);
    ;
    callback(object.data);
    if(object.data.type=='success'){
      dispatch({
        type: 'EMAIL_SUBSCRIBE_SUCCESS',
        d_type: data.sub_type,
        data: object.data.data
      })
    }else{
      dispatch({
        type: 'EMAIL_SUBSCRIBE_ERROR',
        data:{},
        message: object.data.message
      })
    }
 
  }
}


export function registerForDrop(data,callback){
  return async dispatch => {
    dispatch({
      type: "DROP_REGISTER_REQUEST"
    })

    let object = await axios.post('/api/v1/drop/apply', data);
    ;
    callback(object.data);
    if(object.data.type=='success'){
      dispatch({
        type: 'GET_DROP_SUCCESS',
        data:object.data.drop,
      })

      dispatch({
        type: 'DROP_REGISTER_SUCCESS',
        d_type: data.sub_type,
        data: object.data.data
      })
    }else{
      dispatch({
        type: 'DROP_REGISTER_ERROR',
        data:{},
        message: object.data.message
      })
    }
  }
}



export function claimForDrop(data,callback){
  return async dispatch => {
    dispatch({
      type: "DROP_REGISTER_REQUEST"
    })

    let object = await axios.post('/api/v1/drop/claim', data);
    ;
    callback(object.data);
    if(object.data.type=='success'){
      dispatch({
        type: 'GET_DROP_SUCCESS',
        data:object.data.drop,
      })

      dispatch({
        type: 'GET_CLAIM_SUCCESS',
        data:object.data.claim,
      })

      dispatch({
        type: 'DROP_REGISTER_SUCCESS',
        d_type: data.sub_type,
        data: object.data.data
      })
    }else{
      dispatch({
        type: 'DROP_REGISTER_ERROR',
        data:{},
        message: object.data.message
      })
    }
  }
}


export function blockClaimSlot(data,callback){
  return async dispatch => {
    dispatch({
      type: "DROP_REGISTER_REQUEST"
    })

    let object = await axios.post('/api/v1/drop/blockClaimSlot', data);
    ;
    callback(object.data);
    if(object.data.type=='success'){
      dispatch({
        type: 'GET_DROP_SUCCESS',
        data:object.data.drop,
      })

      dispatch({
        type: 'GET_CLAIM_SUCCESS',
        data:object.data.claim,
      })

      dispatch({
        type: 'DROP_REGISTER_SUCCESS',
        d_type: data.sub_type,
        data: object.data.data
      })
    }else{
      dispatch({
        type: 'DROP_REGISTER_ERROR',
        data:{},
        message: object.data.message
      })
    }
  }
}




export function setFormSubmitFunction(fn){
  return async dispatch => {
    dispatch({
      type: "SET_FORM_FUN",
      data:fn
    }) 
  }
}

export function goToBack(fun){
  return async dispatch => {
    dispatch({
      type: "SET_BACKBUTTON",
      data:fun
    }) 
  }
}

export function resetBack(){
  return async dispatch => {
    dispatch({
      type: "RESET_BACKBUTTON",
      data:null
    }) 
  }
}