import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

export const ConnectStep = (props) => {
  const { step_number, title, src, page } = props;
  
  return (
    <>
      <div className="card collection-card my-3">
        <div className={props.gutters ? "row g-2" : "row g-3"}>
          <div className="col-4 col-lg-2 img_area">
            <img src={src} className="img-fluid rounded-circle" alt="..." width={90}/>
          </div>
          <div className="col-8 col-lg-10">
            <div className="card-body">
              <div className={page.width < 768 ? "steps justify-content-end" : "steps"}>
                <Link className={(step_number == 1) ? 'active' : ( step_number > 1 ?  'complete' : "")} to="#"></Link>
                <Link className={(step_number == 2) ? 'active' :  ( step_number > 2 ?  'complete' : "")} to="#"></Link>
                {/* <Link className={(step_number == 3) ? 'active' :  ( step_number > 3 ?  'complete' : "")} to="#"></Link> */}
              </div>
              {
                page.width >= 768 &&
                <h5 className="card-title text-white">{title}</h5>
              }
            </div>
          </div>
          {
            page.width < 768 &&
            <h5 className="card-title text-white mt-4">{title}</h5>
          }
        </div>
      </div>
    </>
  )
}


const ConnectClaimStep1 = (props)  => {
  const { step_number, title, src, checked, page,activeDrop } = props;

  return (
    <>
      <div className="card collection-card my-3">
        <div className="row g-0">
          <div className="col-4 col-lg-2 img_area">
            <img src={activeDrop.data.logo_url} className="img-fluid rounded-circle" alt="..."  width={90}/>
            {
              checked &&
              <div className="wallet_registered ">
                <div className='text-end'><i></i></div>
                {/* <p>You are registered!</p> */}
              </div>
            }
          </div>
          <div className="col-8 col-lg-10">
            <div className="card-body">
              <div className={page.width < 768 ? "steps justify-content-end" : "steps"}>
                <Link className={(step_number == 1) ? 'active' :  ( step_number > 1 ?  'complete' : "")} to="#"></Link>
                <Link className={(step_number == 2) ? 'active' :  ( step_number > 2 ?  'complete' : "")} to="#"></Link>
              </div>
              {
                page.width >= 768 &&
                <h5 className="card-title text-white">{title}</h5>
              }
            </div>
          </div>

          {
            page.width < 768 &&
            <h5 className="card-title text-white mt-5">{title}</h5>
          }
        </div>
      </div>
    </>
  )
}





const mapStateToProps = (state) => ({
  page: state.page,
  activeDrop: state.activeDrop,
})

const mapDispatchToProps = {
}

export const ConnectClaimStep = connect(mapStateToProps, mapDispatchToProps)(ConnectClaimStep1)

