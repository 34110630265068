export function  userReducer(state = {type:"",login:false,providerType:"",data:false,network:"",provider:"",message:"",dropNft:null,register:null,claim:null}, action){
  let obj;
  let tmp=[];
   obj ={...state};
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {type:"request",login:false,providerType:action.providerType,data:false,network:"",provider:"",message:"",dropNft:null}   
    case "LOGIN_RESET":
      return {type:"",login:false,providerType:obj.providerType,data:false,network:"",provider:"",message:"",dropNft:null}
    case "LOGIN_SUCCESS":
      return {type:"success",login:true,providerType:obj.providerType,data:action.data,network:action.network,provider:action.provider,dropNft:action.dropNft,register:action.register,claim:action.claim}
    case "LOGIN_ERROR":
      return {type:"error",login:false,providerType:obj.providerType,data:false,network:"",provider:"",message:action.message,dropNft:null}  
    case "GET_CLAIM_SUCCESS":
      obj['claim'] = action.data;
      return obj;
  }
  return state;
}
