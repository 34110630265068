import React,{useState} from 'react'
import { connect } from 'react-redux';

import {ValidateEmail} from "../../utils/index"

import {subscribeEmail} from "../../services/actions";

function Notify(props) {
  const [email,setEmail] = useState('');
  const [error,setError] = useState('');
  const [success,setSuccess] = useState('');
  const [request,setRequest] = useState(false);
  const subscribe= ()=>{
  setError('')
  setSuccess('')
  let data = {sub_type:"drops",email:email}
    if(ValidateEmail(email)){
      props.subscribeEmail(data,(result)=>{
        if(result.type =='error'){
          setError(result.message.join(","));
        }else{
          setEmail('');
          setSuccess(result.message.join(","));
        }
      })
    }else{
      setError('Invalid email. Please try again.')
    }
  }


  const setValue=(value)=>{
    value = value.trim();
    setEmail(value);
    setError('')
    setSuccess('')
  }
  
    return (
        <div>
            <section className={ props.no_spacing ? "next-drop" : "next-drop py-4"}>
                <div className="container">
                  <div className="row">
                    {/* <div className="col-lg-6 d-flex align-items-center">
                      <img src="/assets/images/timer-1.png" alt="" />
                      <h4 className="next-drop-title">&nbsp; NEXT DROP</h4>
                    </div> */}
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <h5 className={ props.no_spacing ? "text-center mt-1" : "mt-3"}>Get notified about upcoming drops!</h5>
                        <div className={error=="" ? "input-group mt-0 mb-3" : "input-group mt-0 mb-3 input-error" }>
                          <span className="input-group-text" id="inputGroupPrepend"><i className="ri-mail-line" /></span>
                          <input value={email} type="email" id="next_drop_email" className="form-control" placeholder="Enter Your Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e)=>{setValue(e.target.value)}} />
                          <button className={ props.no_spacing ? "newsletter-form-submit btn bordered_btn" : "newsletter-form-submit btn"} type="button" id="button-addon2" onClick={subscribe}>Submit</button>
                        </div>
                        {success!="" &&
                          <p className="form-text text-white">
                            <i class="ri-checkbox-circle-line text-white"></i> {success}
                          </p>
                        }
                        {error!="" &&
                        <p className="form-text text-danger">
                          <i class="ri-close-circle-line text-danger"></i> {error}
                        </p>
                        }
                    </div>
                  </div>
                </div>
              </section>
        </div>
    )
}


const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
  subscribeEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(Notify)
