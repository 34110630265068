import React,{useState} from 'react'
import { connect } from 'react-redux'
import Footer from '../../components/Footer';
import Notify from '../../components/Notify';
import Pxdrops from '../../components/px-drops';
import Recommend from '../../components/Recommend';

import {ValidateEmail} from "../../utils/index"

import {subscribeEmail} from "../../services/actions";

const Sponsor = (props) => {

  const [dropChk,setDropChk] = useState(false);
  const [email,setEmail] = useState('');
  const [error,setError] = useState('');
  const [success,setSuccess] = useState('');
  const [request,setRequest] = useState(false);
  const subscribe= ()=>{
  setError('')
  setSuccess('')
  let data = {sub_type:(dropChk ? "sponsor" : "sponsor_drops"),email:email}
    if(ValidateEmail(email)){
      props.subscribeEmail(data,(result)=>{
        if(result.type =='error'){
          setError(result.message.join(","));
        }else{
          setEmail('');
          setSuccess(result.message.join(","));
        }
      })
    }else{
      setError('Invalid email. Please try again.')
    }
  }


  const setValue=(e)=>{
    let value = e.target.value;
    value = value.trim();
    setEmail(value);
    setError('')
    setSuccess('')
  }

  const chkEnterKey = (e)=>{
    if(e.keyCode == 13){
      subscribe();
    }
  }

  return (
    <div>
      <div className="container pt-5">
        <div className="row justify-content-center sponsor">
          <div className="col-xl-10">
            <div className="row justify-content-center">
              <div className="col-xl-7  sponsor-content">
              <h1 className='text-center'>Want to Sponsor a Drop?</h1>
              <form className="my-3" onSubmit={(e)=>{e.preventDefault()}}>
                <div>
                  <label htmlFor="exampleInputEmail1" className="form-label">Let us know where to send more info</label>
                  <div className="input-group my-3">
                    <div className="input-group">
                      <span className="input-group-text" id="inputGroupPrepend"><i className="ri-mail-line" /></span>
                      <input value={email} type="email" className={error=="" ? "form-control" : "form-control form-control-error" } placeholder="Enter Your Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e)=>{setValue(e)}} onKeyDown={chkEnterKey}/>
                    </div>
                  </div>
                </div>
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={dropChk} onClick={()=>{setDropChk(!dropChk)}}/>
                  <label className="form-check-label" for="exampleCheck1">Send me news of upcoming drops</label>
                </div>
                <div>
                {success!="" &&
                  <p className="form-text">
                    <i class="ri-checkbox-circle-line"></i> {success}
                  </p>
                }
                {error!="" &&
                <p className="form-text">
                  <i class="ri-close-circle-line"></i> {error}
                </p>
                }
                </div>
                <div className="error-box mt-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-text d-flex align-items-center"><i className="ri-checkbox-circle-line me-2"></i>Sit tight, we’ll be in touch!</div>
                      <div className="card-text pt-2">Someone from the PX Drops team will be in touch shortly with details about sponsoring a drop and help you understand the benefits.</div>
                    </div>
                  </div>
                </div>
              </form>
              <div className='pt-1 functionality'>
                <div>
                  <i className="ri-team-line ri-2x gradiant_icon"></i>
                  <h2>EXTEND YOUR REACH</h2>
                  <p>PX Drops puts creators and brands in front of targeted, valuable NFT communities that love to share with other members and cohorts. Provide  rewards for a few, and watch it spread to the whole community.</p>
                </div>
                <div>
                  <i className="ri-rocket-2-line ri-2x gradiant_icon"></i>
                  <h2>CROSSOVER INTO WEB3</h2>
                  <p>Want to reach web3 audiences but don't know how? PX Drops is an easy, frictionless way to engage with valuable NFT communities and their networks.</p>
                </div>
                <div>
                  <i className="ri-star-line ri-2x gradiant_icon"></i>
                  <h2>REACH A NEW AUDIENCE</h2>
                  <p>PX Drops is the easiest way to reach a specific project, or a category of NFT communities. You provide the reward, and we'll bring the audience you want to engage.</p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-11 nft-drops pt-5">
                  <Pxdrops {...props}/>
                </div>
              </div>
              <div className='pt-5 nft-drops'>
                <Recommend {...props}/>
              </div>
            </div>
          </div>
          <Notify {...props}/>
          <Footer {...props}/>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
  subscribeEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(Sponsor)
