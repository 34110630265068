import React, {useRef,useEffect,useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TinySlider from '../../components/Slider/tinyslider';
import DropTimer from './drop_timer';
import TimerClaim from './timer_claim';

import { connect } from 'react-redux'
import {setPageArea} from '../../services/actions';
import {showUTCTime,addToCalendar,copyText} from "../../utils/index"

import moment from "moment";
import MultiSponsor from '../MultiSponsor';
import MultiSponsorList from '../../components/MultiSponsor/list';
import SingleCollectionCard from '../Collectioncard/single';


const Tshirt = (props) => {

  const listRef = useRef(null);
  const [displayMultiSponsor, setDisplayMultiSponsor] = useState(false);
  const {activeDrop,user,dropDuration,location} = props;
  function notify(msg) {
    toast.info(msg);
  }


  const share_twitter = ()=>{
    let type ="";
    let text =`Unlock new rewards for different NFT communities every week with PXDrops! Follow @PolyientX and see if your community is eligible for a free reward. 👉`;


    if(location.pathname =="/user-registered"){
      text = `Just registered for PXDrops! Unlock new rewards for different NFT communities every week. Follow @PolyientX and see if your community is eligible for a free reward.`
    } 

    if(location.pathname =="/delivery"){
      text = `Just claimed a Tshirt  for holding a ${activeDrop.data.dropName} with PXDrops! Unlock new rewards for different NFT communities every week. Follow @PolyientX and see if your community is eligible for a free reward.`
    } 


    window.open("https://twitter.com/share?text="+text+"&url=https://www.pxdrops.com/drop/"+activeDrop.data.url_handle+"&hashtags=PXDrops,NFTs,NFTCommunity");
    // constants.GPageEvent("Social","Twitter-Share","Share");
  }

  return (
    <>
      {
        !displayMultiSponsor ?
        <div>
          <div className={props.page.width < 768 ? "card bg-bo  dy-dark" : "card"}>
          {(user.claim && moment(user.claim.claimExpireDate).utc() >= moment().utc()) ? 
              <TimerClaim/>
            :
            <DropTimer activeDrop={activeDrop} dropDuration={dropDuration}/>
          }
          
            
            <div className={activeDrop.data.available_drops == 0 ? "container d-block d-sm-none mt-5" : "container d-block d-sm-none"}>
              <div className='text-white ps-4 pe-4'>
                <h2 className="text-white heading mt-3" style={{fontFamily:'Beatrice',fontSize:"1.3rem"}}>Rewards for NFT Communities </h2>
                <p style={{color:"#C7C8C9"}}>We reward NFT holders with merch, digital goods and more. </p>
              </div>
            </div>
          
            <TinySlider listRef={listRef}>
              <ul ref={listRef}>
                {activeDrop.data.banner_images.map((item,index)=>{
                  return (<li key={index}><img src={item} className="img-fluid" /></li>)
                })
                }
              </ul>
            </TinySlider>

            <div className="card-body t-shirt-card-body">
              <div className='d-flex flex-column align-items-center'>
              <div>
                
                <div className="text-overlay">
                  <p>{activeDrop.data.banner_label}</p>
                  {/* <a href="#" className="btn">{activeDrop.data.available_drops} Available</a>
                  <div className="overlay-bottom">Sponsored by {activeDrop.data.dropName}</div> */}
                </div>

                <div className="px-4ss">
                  {activeDrop.data.sponsors_data.length > 1 ? 
                    <div>
                      <MultiSponsor {...props} setDisplayMultiSponsor={setDisplayMultiSponsor} />
                    </div>
                  :
                    <div className="d-flex align-items-start my-3">
                      <div className="flex-shrink-0">
                        <img src={activeDrop.data.logo_url} alt={activeDrop.data.dropName} className="rounded-circle" width={75} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h5 className="heading_md" style={{color: '#C7C8C9'}}>An exclusive reward for</h5>
                        <h4 className="heading_h3"><u>{activeDrop.data.nft_name}</u></h4>
                      </div>
                    </div>
                  }
                
                  <div className='d-nones d-sm-blocks'>
                    <div className="drop_details_area">
                      <p className="card-text d-flex align-items-center" style={{fontWeight: 'bold'}}>Drop Details</p>
                      <p className="card-text d-flex align-items-center" style={{color: '#ECE75B'}}><i className="ri-calendar-line me-2" style={{color: '#ECE75B'}} /><span onClick={()=>{addToCalendar(activeDrop.data)}} style={{cursor:"pointer"}}>  {showUTCTime(activeDrop.data.drp_date_time_utc)} UTC</span></p>
                      <p className="card-text d-flex align-items-center" style={{color: '#C7C8C9'}}><i className="ri-flag-line me-2" style={{color: '#C7C8C9'}} /> {activeDrop.data.available_drops} Available</p>
                      <p className="card-text d-flex align-items-center" style={{color: '#C7C8C9'}}><i className="ri-store-2-line me-2" style={{color: '#C7C8C9'}}/> Sponsored by  <a href={activeDrop.data.sponsor_external_link} target="_blank" className='ps-1 sponsor-site' style={{color: '#C7C8C9'}}> {activeDrop.data.sponsored_display_name} </a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none d-sm-block">
              <div className='social-media-share pt-4 d-flex justify-content-evenly'>
                <p className='share-twitter d-flex align-items-center' style={{color: '#00D1DE'}} onClick={() => share_twitter()}><i className="ri-twitter-line me-2" /> Share this drop</p>
                {/* <p className='share-discord text-white d-flex align-items-center'  onClick={()=>{copyText("https://pxdrops.com");toast.info("Link copied!");}} style={{cursor:"pointer"}}><i className="ri-discord-line me-2" /> Share on Discord</p> */}
              </div>

            </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        :
        <MultiSponsorList {...props} actualDrop={activeDrop.data} sponsorList={setDisplayMultiSponsor} from_tshirt_area={true} />
      }
    </>
  )
}




const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
})

const mapDispatchToProps = {
  setPageArea
}

export default connect(mapStateToProps, mapDispatchToProps)(Tshirt)