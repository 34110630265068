import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import IndividualSponsor from './individual';
import Modal from 'react-bootstrap/Modal'
import {goToBack} from "../../services/actions";

const MultiSponsorList = (props) => {
  const { page, actualDrop, sponsorList, modaldisplay, from_tshirt_area } = props;
  console.log("from_tshirt_area =>",from_tshirt_area)
  useEffect(()=>{
    if(document.getElementById('topheader')){
      document.getElementById('topheader').scrollIntoView({behavior: 'smooth'});
    }

    if (modaldisplay) {
      props.goToBack(()=>{ 
        sponsorList(false);
      });
    }
  },[]);

  return (
    <div>
      {
        modaldisplay ?

        <>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h5 className="heading_sm" style={{color: '#C7C8C9'}}>Eligible Communities</h5>
              <h4 className="heading_lg mb-3">{actualDrop.nft_name}</h4>
            </div>
          </div>
          <div className="row mt-3 text-center prev_multi_sponsors_list">
            {actualDrop.sponsors_data.map((v,index)=> 
                <div className="col-6 col-md-4">
                  <IndividualSponsor sponsor={v} key={index}/>
                </div>
              )}
          </div>
        </>
        :
        <div className={ props.from_opensea ? "multi_sponsors_list rightaligned_box px-4" : "multi_sponsors_list px-4"}>
          <div className="row justify-content-center pt-5">
            <div className="col-8 col-md-9">
              {
                from_tshirt_area &&
                <span className="prev_link text-primary cursor-pointer mb-3" onClick={() => sponsorList(false)}><i className="ri-arrow-left-line text-primary me-2"></i> Back</span>
              }
              <h5 className="heading_sm" style={{color: '#C7C8C9'}}>Eligible Communities</h5>
              <h4 className="heading_h3 mb-0">{actualDrop.nft_name}</h4>


              <div className="row mt-3">
                {actualDrop.sponsors_data.map((v,index)=> 
                  <div className={ from_tshirt_area ? "col-6" : "col-4"}>
                    <IndividualSponsor sponsor={v} key={index}/>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  page: state.page,
  backButton: state.backButton,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiSponsorList);

