import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const Error = (props) => {
  return (
    <div>
      
    </div>
  )
}

Error.propTypes = {
  props: PropTypes
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Error)
