import React from 'react'
import { connect } from 'react-redux'

import { Accordion } from 'react-bootstrap'
import {ConnectStep} from '../../components/ConnectStep'
import Instructions from '../../components/instructions'
import {Eligibility} from '../../components/instructions/eligibility'
import Pxdrops from '../../components/px-drops'
import Recommend from '../../components/Recommend'
// import '../../styles/app.scss'
import {showUTCTime,addToCalendar} from "../../utils/index";

function Register(props) {
    const {activeDrop}= props;
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <ConnectStep src={activeDrop.data.logo_url} step_number="1" title="Register to Claim this Drop" {...props}/>
                        {
                            props.page.width < 768 &&
                        
                            <div className="text-white border-top border-light py-3 ">
                                <p className="card-text d-flex align-items-center"><i className="ri-time-line me-2" /><span className='text-decoration-underline'  onClick={()=>{addToCalendar(activeDrop.data)}} style={{cursor:"pointer"}}> {showUTCTime(activeDrop.data.drp_date_time_utc)}</span><span className="ps-2" style={{color: "#00D1DE"}}>UTC</span></p>
                                <p className="card-text d-flex align-items-center"><i className="ri-flag-line me-2" /> {activeDrop.data.available_drops} Available</p>
                                <p className="card-text d-flex align-items-center"><i className="ri-store-2-line me-2" /> Sponsored by: {activeDrop.data.dropName}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            
            {/* <Eligibility {...props}/> */}

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <Instructions/>
                        
                        {/* <div className='mt-4'>
                            <Recommend {...props}/>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 nft-drops pt-5">
                                <Pxdrops {...props}/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user:state.user,
    activeDrop:state.activeDrop,
    dropDuration:state.dropDuration,
    page:state.page,
})

const mapDispatchToProps = {
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Register)
  