import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

const Pxdrops = (props) => {

    return (
      <div className="px-md-5">
        <h2 className="px-drops">What are PX Drops?</h2>
        <p className="get-more">Get more out of your NFTs. Each week, we'll drop new goodies just for holding specific NFTs (think free gifts, early access and more!). </p>
        <div className='d-flex align-items-center'>
          <a href="https://www.polyientx.com/" className="polyientx-site learn-more" target='_blank'>Brought to you by PolyientX <i className="ri-external-link-line"></i></a>
        </div>
      </div>
    )
}



const mapStateToProps = (state) => ({
  page: state.page,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Pxdrops)

