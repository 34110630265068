import React from 'react'

const HowToClaim = (props) => {
  return (
    <div className="how_to_claim">
      <div className="containers">
        <h3 className="heading_h3" style={{fontSize: '24px'}}>How to claim</h3>
        <ul className="list-group">
          <li className="list-group-item">
            <h4 className="heading_h4 text-primary">STEP 1</h4>
            <h4 className="heading_h4 text-white">Connect your wallet to register for the drop</h4>
            <p>We'll check your wallet for an eligible NFT. <span className="text-warning-2">Only registered wallets have a chance to claim the reward.</span></p>
          </li>


          <li className="list-group-item">
            <h4 className="heading_h4 text-primary">STEP 2</h4>
            <h4 className="heading_h4 text-white">Come back on claim day</h4>
            <p>When the drop goes live, come back for a chance to claim with your registered wallet. Drops are limited, so show up early!</p>
          </li>


          <li className="list-group-item">
            <h4 className="heading_h4 text-primary">STEP 3</h4>
            <h4 className="heading_h4 text-white">Tell us where to send your reward</h4>
            <p>Share your email or mailing address, and we'll deliver your reward.</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HowToClaim;