import {connectMetamask,connectWallet,web3signature,dropNFTInformation} from "../../utils/ethereum";
import {registerUser} from "./api"
  

export function userLogin(type,activeDrop){
  const networkChainId = activeDrop.network_chainId;
  return (dispatch)=>{
    let connect;
    if(type == 'wallet'){
      connect = connectWallet
    }else{
      connect = connectMetamask
    }
    dispatch({type:"LOGIN_REQUEST",providerType:type})

    connect(networkChainId,async(obj)=>{
      console.log("connect - obj",obj);
      if(obj.type=='success'){
        const data = obj.data;
        let currentTime = new Date().getTime() ;
        // let message = "Please sign this message to login. - "+currentTime;
        let message = `Welcome to PX Drops!\n\nSigning this message will scan your wallet for an\neligible NFT. It will not trigger a blockchain\ntransaction, cost fees or allow us to transfer\nyour NFTs.`;

        web3signature(message,data.eth_address,async(webObj)=>{
          if(webObj.type=='success'){
            try{
              let apiData = await registerUser({eth_address:data.eth_address,time_stamp:currentTime,signature:webObj.data.signature});
              const nftInfo = await dropNFTInformation(apiData.drop,data.eth_address);
              console.log("nftINfo",nftInfo);

              dispatch({type:"LOGIN_SUCCESS",data:{...apiData.data,time_stamp:currentTime},network:data.network,provider:(type == 'wallet') ? "wallet":"metamask",drop:apiData.drop,dropNft:nftInfo,register:apiData.register,claim:apiData.claim})
            }catch(e){
              dispatch({type:"LOGIN_ERROR",message:e.message});
            }
          }else{
            dispatch({type:"LOGIN_ERROR",message:webObj.message});
          }
        })
       
      }else{
        dispatch({type:"LOGIN_ERROR",message:obj.message});
      }
      
    },dispatch)
  }
}

export function userReset(){
   return dispatch =>{  dispatch({type:"LOGIN_RESET"});}
}



export function deployContractReset(){
  return dispatch =>{  dispatch({type:"DEPLOY_CONTRACT_RESET"});}
}