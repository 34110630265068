import React, { useState, useEffect } from 'react';
import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';

const TinySlider = (props) => {
  const {children, listRef} = props;
  const [slider, setSlider] = useState(null);

  useEffect(() => {
    if (!slider) {
      setSlider(
        tns({
          container: listRef.current,
          items: 1,
          slideBy: 'page',
          autoplay: false,
          center: true,
          controls: false,
          navPosition: 'bottom',
          mouseDrag: true
        })
      );
    }
  }, [slider, listRef]);

  return <div>{children}</div>;
};

export default TinySlider;