import React from 'react'
// import '../../styles/_index.scss'

function Recommend() {
    return (
        <div>
            <div className="card recommend py-2">
                <div className="card-body">
                <h5 className="card-title">Want to make someone's day?</h5>
                <a href="#a" className="pxbtn text-white mt-3"><span className="px-3 py-4"><i className="ri-twitter-line"></i> Share on Twitter</span></a>
                </div>
            </div>
        </div>
    )
}

export default Recommend
