import React from 'react'
import { connect } from 'react-redux'
import Collectioncard from '../../components/Collectioncard';
import { ConnectClaimStep } from '../../components/ConnectStep'
import Instructions from '../../components/instructions';
import Pxdrops from '../../components/px-drops';
import Recommend from '../../components/Recommend';
import SponsorCard from '../../components/SponsorCard';

function RegistrationClosed(props) {
    return (
      <>
        <div className="header_area my-5">
          <h1 className="lg">Registration Is Closed</h1>
          <p className="heading_normal">Only those who register at least 3 hours before the drop time are eligible to win.</p>
          <p className="heading_normal text-warning">But it's not to late to:</p>
        </div>


        <div className="feedback-mobile mobile-only mb-3">
          <h3>WIN A TICKET</h3>
          <div className="bgbox">
            <p style={{color: '#FCFCFC'}} className="mb-1">Answer and be entered to win</p>
            <p className="d-flex align-items-center mb-1"><i className="ri-ticket-line me-2"></i> Answer this poll to be entered to win a ticket to NFT.NYC</p>
            <a href="#a"  className='giveaway-survey'>Complete a survey to register <i className="ri-external-link-line"></i></a>
          </div>
        </div>
        
        <div className="shop desktop-only">
          <h3>PROVIDE YOUR FEEDBACK</h3>
          <p style={{color: '#FCFCFC'}}>Answer and be entered to win</p>
          <p className='d-flex align-items-center'><i className="ri-ticket-line text-warning me-2"></i> Answer this poll to be entered to win a ticket to <span className="text-warning">&nbsp; NFT.NYC</span></p>
        </div>
      </>
    )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
  activeDrop:state.activeDrop,
  dropDuration:state.dropDuration,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationClosed);