import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ETH_ENV} from '../services/constants'
import chainObject from "../services/constants/chains.json"
import {ERC20} from "../services/abi/ERC20"
import {ERC721} from "../services/abi/ERC721"

import axios from "axios";
const ethereum = window.ethereum;
// for ethereum interaction function
export const web3 = new Web3();
window.web3Obj = web3;

export async function connectWallet(chain_id,callback,dispatch){
  let chainObj = {}
  for (let index = 0; index < chainObject.length; index++) {
    const element = chainObject[index];
    // if(chain_id == element.chainId){
      chainObj[element.chainId] = element.rpc[0];
    // }
  }
  console.log("chainObj",chainObj);

  let provider = new WalletConnectProvider({
    rpc: chainObj, // Required (from metamask mainnet)
  });
  
  await provider.close().catch(async(error)=>{
    console.log("error",error);
  })

  provider = new WalletConnectProvider({
    rpc: chainObj, // Required (from metamask mainnet)
  });
  let accounts = await provider.enable().catch((error)=>{
      dispatch({type:"LOGIN_RESET"});
  });
  web3.setProvider(provider);  

  web3.eth.net.getNetworkType().then(async(network_type) => {
    let chain = chainObject.find(v=> v.chainId == provider.chainId);
    let network = {}
    network['type'] = chain.network;
    // network['chainId'] = chain.chainId;
    network['chainId'] = parseInt(chain_id);
    console.log("network",network);
    callback({type:"success",data:{network:network,eth_address:accounts[0]}});
  })

  provider.on("accountsChanged", (accounts) => {
    // alert("accountsChanged")
    // window.location.reload();
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId) => {
    let network = {}
    web3.eth.net.getNetworkType().then(async(network_type) => {
      let chain = chainObject.find(v=> v.chainId == chainId);
      network['type'] = chain.network;
      network['chainId'] = chain.chainId;
      network['networkVersion'] = "";
      dispatch({type:"NETWORK_CHANGE",network:network});
    });
  });
  // Subscribe to session disconnection
  provider.on("disconnect", (code, reason) => {
    window.location.reload();
  });
}


export async function connectMetamask(networkChainId,callback){
  if (typeof window.ethereum === 'undefined') {
    window.open("https://metamask.app.link/dapp/" +window.location.host);
    return
  } 
  ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
    ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });

    if (ethereum.isMetaMask && ethereum.selectedAddress != null) { 
        detectEthereumProvider().then(provider=>{
          web3.setProvider(provider);
          web3.eth.net.getNetworkType().then(async(network_type) => {
            let network = {}
            network['type'] = network_type;
            network['chainId'] = await ethereum.request({ method: 'eth_chainId' });
            network['networkVersion'] = await ethereum.request({ method: 'net_version' });
            if(networkChainId != network['chainId']){
              return walletSwitchEthereumChain(networkChainId,'metamask',(data)=>{
                if(data.type=='success'){
                  connectMetamask(networkChainId,callback);
                }else{
                  callback(data);
                }
              });
            }
            callback({type:"success",data:{network:network,eth_address:ethereum.selectedAddress}});
          })
        });
    }else{
      callback({type:"error",message: "Some error occurred!"});
    } 
  }).catch((error)=>{
    callback({type:"error",message: error.message});
  });
}

export async function web3signature(message,address,callback){
  try{
    var signature = await web3.eth.personal.sign(message, address);
    callback({type:"success",data:{signature}});
  }catch(error){
    callback({type:"error",message: error.message});
  }
}

export function getHaxChainId(id){
  return "0x"+(id).toString(16)
}

export async function walletAddEthereumChain(chainId,callback){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  // wallet_addEthereumChain
  let object = { 
    chainId: getHaxChainId(chainId), // A 0x-prefixed hexadecimal string
    chain: "Matic Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
    iconUrls: ["https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png"]
  }

  if(!chain){
    callback({type:"error",message:"network chain - "+chainId+" not found!"});
    return 
  }else{
    object={ 
      chainId: getHaxChainId(chain.chainId), // A 0x-prefixed hexadecimal string
      chainName: chain.name,
      nativeCurrency: chain.nativeCurrency,
      rpcUrls: chain.rpc,
      blockExplorerUrls: (chain.explorers.length >0 ? chain.explorers.map((e)=>e.url) : null),
      iconUrls: ["https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png"]
    }
  }
  console.log("final",object);
  try {
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [object],
    });
    callback({type:"success",message:"wallet added to chain"});
  } catch (addError) {
    callback({type:"error",message:addError.message});
  }
}

export async function walletSwitchEthereumChain(chainID,provider,callback){
  if(provider == 'metamask'){
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: getHaxChainId(parseInt(chainID)) }],
      });
      callback({type:"success",message:"network get switched to "+chainID});
    } catch (switchError) {
      if (switchError.code === 4902) {
        walletAddEthereumChain(chainID,(data)=>{
          if(data.type=='success'){
            walletSwitchEthereumChain(chainID,callback);
          }else{
            callback(data);
          }
        });
      }else{
        callback({type:"error",message:switchError.message});
      }
    }
  }
}

export async function getAllTransferPastEvents(contract,fromBlock,toBlock){
  fromBlock = parseInt(fromBlock);
  toBlock = parseInt(toBlock);
  let pastEvents =[];
  try{
   pastEvents = await contract.getPastEvents('Transfer', {fromBlock: fromBlock,toBlock: toBlock});
  }catch(e){
     if(['Returned error: query returned more than 10000 results','Returned error: context deadline exceeded','Returned error: request expired','query returned more than 10000 results'].includes(e.message)){
        const middle = Math.round((fromBlock + toBlock) / 2);
        let fEvents =  await getAllTransferPastEvents(contract,fromBlock, middle);
        let tEvents = await getAllTransferPastEvents(contract,middle + 1, toBlock);
        pastEvents = [...fEvents,...tEvents];
     }
  }
  pastEvents = pastEvents.sort((a,b) => (a.blockNumber > b.blockNumber) ? 1 : ((b.blockNumber > a.blockNumber) ? -1 : 0))
  return pastEvents
}

async function getOwnedTokens(contract,account) {
  console.log("contract,account",contract,account);
  // const latest = await web3.eth.getBlockNumber();
  // console.log("contract,latest",latest);
  // let all_logs = await getAllTransferPastEvents(contract,0,latest);
  // console.log("all_logs",all_logs);
  let sentLogs = await contract.getPastEvents("Transfer",{
    filter: {from: account},
    fromBlock: 0,
    toBlock: 'latest'
   })

   console.log("sentLogs",sentLogs);

   let receivedLogs = await contract.getPastEvents("Transfer",{
    filter: {to: account},
    fromBlock: 0,
    toBlock: 'latest'
   })

   console.log("receivedLogs",receivedLogs);
   
  const logs = sentLogs.concat(receivedLogs)
    .sort(
      (a, b) =>
        a.blockNumber - b.blockNumber ||
        a.transactionIndex - b.TransactionIndex,
    );

  // const logs = all_logs.sort(
  //     (a, b) =>
  //       a.blockNumber - b.blockNumber ||
  //       a.transactionIndex - b.TransactionIndex,
  //   );

  let owned = new Set();

  for (const log of logs) {
    const { from, to, tokenId } = log.returnValues;
    if (addressEqual(to, account)) {
      owned.add(tokenId.toString());
    } else if (addressEqual(from, account)) {
      owned.delete(tokenId.toString());
    }
  }
  owned = [...owned];
  console.log("owned",owned);
  return owned;
};

function addressEqual(to,from){
  return to.toString().toLowerCase() == from.toString().toLowerCase();
}


export async function dropNFTInformation(drop,eth_address){
  const nftAddress =drop.sponsors_data.map(v=>v.nft_address);
  console.log("nftAddress",nftAddress);
  let  nftInfo=null;
  for (let index = 0; index < nftAddress.length; index++) {
    const address = nftAddress[index];
    let contract = new web3.eth.Contract(ERC721,address);
    let balance = await contract.methods.balanceOf(eth_address).call();
    console.log(address,"balance",balance);
    if(balance > 0){
      let tokenId = null;
      try{  
         tokenId = await contract.methods.tokenOfOwnerByIndex(eth_address,0).call();
         console.log("tokenIdtokenId",tokenId);
      }catch(e){
        let tokenIds = await getOwnedTokens(contract,eth_address);
        if(tokenIds.length > 0) {
          tokenId = tokenIds[0];
        }else{
          return {nftAddr:address,ownBalance:balance,tokenInfo:{image:drop.logo_url},tokenId:""}
        }
      }
      if(tokenId !=null){
        let tokenUri = await contract.methods.tokenURI(tokenId).call();
        let tokenInfo = null;
        try{
          let response = await fetch(tokenUri);
            tokenInfo = await response.json();
            let image = drop.logo_url;
            if(tokenInfo.image){
              image = tokenInfo.image
            }
            tokenInfo ={id:tokenId,image:image,name:tokenInfo.name,description:""};
        }catch(e){
          let  response = await axios.get(`/api/v1/tokenInfo?url=${tokenUri}`);
          if(response.data.type=='success'){
            tokenInfo = response.data.data;
          }else{
            tokenInfo ={id:tokenId,image:drop.logo_url,name:"",description:""};
          }
        }
        nftInfo = {nftAddr:address,ownBalance:balance,tokenInfo:tokenInfo,tokenId:tokenId}
      }
      break;
    }
  }
  return nftInfo;
}

export async function getMyTokenIds(drop,eth_address){
  const nftAddress = drop.nft_address.split(",");
  let  tokenIds=[];
  for (let index = 0; index < nftAddress.length; index++) {
    const address = nftAddress[index];
    let contract = new web3.eth.Contract(ERC721,address);
    let balance = await contract.methods.balanceOf(eth_address).call();
    console.log("balance",balance);
    if(balance > 0){
      try{ 
        for (let i = 0; i < balance; i++) {
          console.log("i",i);
        let tokenId = await contract.methods.tokenOfOwnerByIndex(eth_address,i).call();
        tokenIds.push(tokenId)     
        }  
      }catch(e){
         tokenIds = await getOwnedTokens(contract,eth_address);
      }
    }
    if(tokenIds.length>0){break;}
  }
  return tokenIds;
}


export function deployContract(contractObj,value,userData,callback){
    let object = JSON.parse(contractObj.fileContent);
    const user = userData.data;
    console.log("userData",userData);
    
 try{
    let contract = new web3.eth.Contract(object.abi);
      contract.deploy({data:object.bytecode,arguments:value}).send({from:user.eth_address}, function(error, transactionHash){
        callback({type:"start1",data:{transactionHash,network_type:userData.network.type,network_chain_id:userData.network.chainId}});
      }).on('error', function(error){
        callback({type:"error",data:{network_type:userData.network.type,network_chain_id:userData.network.chainId},message:error.message});
      })
      .on('transactionHash', function(transactionHash){
        callback({type:"start",data:{transactionHash,network_type:userData.network.type,network_chain_id:userData.network.chainId}});
      })
      .on('receipt', function(receipt){
        console.log("sd",{network_type:userData.network.type,network_chain_id:userData.network.chainId,contract_address:receipt.contractAddress,tx_hash:receipt.transactionHash,response:receipt,contract_id:contractObj.id});
        callback({type:"success",data:{network_type:userData.network.type,network_chain_id:userData.network.chainId,contract_address:receipt.contractAddress,tx_hash:receipt.transactionHash,response:receipt,contract_id:contractObj.id}});
       })
  }catch(error){
    callback({type:"error",data:{network_type:userData.network.type,network_chain_id:userData.network.chainId},message:error.message});
  }

}

export async function runContractFun(contractObj,deploy,funName,params,userData,ethVal,callback){
  let object = JSON.parse(contractObj.fileContent);
  const user = userData.data;
  try{
    let contract = new web3.eth.Contract(object.abi,deploy.contract_address);
    let d= null;
    if(funName.stateMutability =='view' || funName.stateMutability =='pure'){
      d = await contract.methods[funName.name](...params).call({})
    }else{
      if(funName.stateMutability =='payable'){
        d = await contract.methods[funName.name](...params).send({from:user.eth_address,value:ethVal})
      }else{
        d = await contract.methods[funName.name](...params).send({from:user.eth_address})
      }
        d = await web3.eth.getTransactionReceipt(d.transactionHash);
    }
    callback({type:"success",data:d,message:""});
  }catch(error){
    callback({type:"error",data:{},message:error.message});
  }
}

export async function listenContractEvent(contractObj,deploy,eventObj,options,callback){
  let object = JSON.parse(contractObj.fileContent);

  try{
    let contract = new web3.eth.Contract(object.abi,deploy.contract_address);
    let event = contract.events[eventObj.name]().on('data',async(event)=>{

      if(event.name =='SetPair'){

      }

      callback({type:"response",event:event});
    })
    callback({type:"success",event:event});
  }catch(error){
    callback({type:"error",data:{},message:error.message});
  }
}

export async function listenEthEvents(contractObj,deploy,userObj,callback){

  let object = JSON.parse(contractObj.fileContent);
  const web3Eth = new Web3(new Web3.providers.WebsocketProvider('wss://rinkeby.infura.io/ws/v3/af459aa169024c76a5485bc71b6e6617'));

  let contract = new web3Eth.eth.Contract(object.abi,'0x21105A6f3aBbcbd1AbaEd4af1b820B9bCBdBa1A1');

  let avaContract = new web3.eth.Contract(object.abi,deploy.contract_address);


  let events =[]
  if(contract.events['SetPair']){
    window.avaContract = avaContract;
    window.rinkContract = contract;
    window.web3Eth = web3Eth;
    let event1 = contract.events['SetPair']().on('data',async(event)=>{
      window.eventData = event;
      console.log("event-eth-SetPair",event);
      console.log("event-eth-SetPair",event.returnValues);
      await avaContract.methods.setPair(event.returnValues._avaAddr,event.returnValues._ethAddr,!event.returnValues._addtochain).send({from:userObj.data.eth_address});
      // await window.avaContract.methods.setPair(window.eventData.returnValues._avaAddr,window.eventData.returnValues._ethAddr,!window.eventData.returnValues._addtochain).call({from:ethereum.selectedAddress});

    })

    events.push(event1);

    let event2 = contract.events['ReceiveToken']().on('data',async(event)=>{
      console.log("event-eth-ReceiveToken",event);
      window.eventData = event;
      console.log("event-eth-ReceiveToken",event);
      console.log("event-eth-ReceiveToken",event.returnValues);
      await avaContract.methods.transferToken(event.returnValues._pairAddr,event.returnValues._to,event.returnValues._value).send({from:userObj.data.eth_address});
    })
    events.push(event2);

    let event3 = contract.events['TransferToken']().on('data',async(event)=>{
      console.log("event-eth-TransferToken",event);
        
    })
    
    events.push(event3);
  }

  callback({type:"success",events:events})

}


export async function verifyContract(contractAddr,sourceCode,abiObj,compileVersion){
  let url ='https://api-rinkeby.etherscan.io/api';

  let  data = {
        apikey: 'CKRJXDS77JGGB79KMV2Z6CABKHF7BQGHFT',                     //A valid API-Key is required        
        module: 'contract',                             //Do not change
        action: 'verifysourcecode',                     //Do not change
        contractaddress: contractAddr,   //Contract Address starts with 0x...     
        sourceCode: sourceCode,             //Contract Source Code (Flattened if necessary)
        codeformat: 'solidity-single-file',             //solidity-single-file (default) or solidity-standard-json-input (for std-input-json-format support
        contractname: abiObj.contractName,         //ContractName (if codeformat=solidity-standard-json-input, then enter contractname as ex: erc20.sol:erc20)
        compilerversion: compileVersion,   // see https://etherscan.io/solcversions for list of support versions
        optimizationUsed: 0, //0 = No Optimization, 1 = Optimization used (applicable when codeformat=solidity-single-file)
        runs: 200,                                      //set to 200 as default unless otherwise  (applicable when codeformat=solidity-single-file)        
        constructorArguements: "",   //if applicable
        evmversion: "",             //leave blank for compiler default, homestead, tangerineWhistle, spuriousDragon, byzantium, constantinople, petersburg, istanbul (applicable when codeformat=solidity-single-file)
        licenseType: 1,           //Valid codes 1-12 where 1=No License .. 12=Apache 2.0, see https://etherscan.io/contract-license-types
        libraryname1: "",         //if applicable, a matching pair with libraryaddress1 required
        libraryaddress1: "",   //if applicable, a matching pair with libraryname1 required
        libraryname2: "",         //if applicable, matching pair required
        libraryaddress2: "",   //if applicable, matching pair required
        libraryname3: "",         //if applicable, matching pair required
        libraryaddress3: "",   //if applicable, matching pair required
        libraryname4: "",         //if applicable, matching pair required
        libraryaddress4: "",   //if applicable, matching pair required
        libraryname5: "",         //if applicable, matching pair required
        libraryaddress5: "",   //if applicable, matching pair required
        libraryname6: "",         //if applicable, matching pair required
        libraryaddress6: "",   //if applicable, matching pair required
        libraryname7: "",         //if applicable, matching pair required
        libraryaddress7: "",   //if applicable, matching pair required
        libraryname8: "",         //if applicable, matching pair required
        libraryaddress8: "",   //if applicable, matching pair required
        libraryname9: "",         //if applicable, matching pair required
        libraryaddress9: "",   //if applicable, matching pair required
        libraryname10: "",       //if applicable, matching pair required
        libraryaddress10: ""  //if applicable, matching pair required
    }


    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    console.log("URL",url);
    console.log("data",data);
    // let response = await axios.post(url, formData);
    let response = await fetch(url, {
      method: 'post',
      body: formData,
  });
    console.log("response",response);

}



// helper function 

export function shortEthAddress(address){
  let addr = web3.utils.toChecksumAddress(address);
  return addr.substr(0,6)+"..."+addr.substr(addr.length-4,4);
}

export function etherUrl(url) {
  if (ETH_ENV == 'production') {
    return "https://etherscan.io"+url;  
  } else {
    return "https://rinkeby.etherscan.io"+url; 
  }
}

export function openseaUrl(url) {
  if (ETH_ENV == 'production') {
    return "https://opensea.io"+url;  
  } else {
     return "https://testnets.opensea.io"+url; 
  }
}


export function getNetworkName(chainId){
 chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);

  if(!chain){
    chain={
      "name": "Private",
      "chainId": chainId,
      "shortName": "eth",
      "chain": "ETH",
      "network": "Private",
      "networkId": "",
      "nativeCurrency": {"name":"Ether","symbol":"ETH","decimals":18},
      "rpc": ["https://mainnet.infura.io/v3/${INFURA_API_KEY}","wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}","https://api.mycryptoapi.com/eth","https://cloudflare-eth.com"],
      "faucets": [],
      "explorers": [],
      "infoURL": "https://ethereum.org"
    }
  }
  return chain
}

export function explorerURL(chainId){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  if(chain && chain.explorers.length > 0){
    return chain.explorers[0].url+"/";
  }else{
    return "#/";
  }
} 


export function validateAddress(address){
  try{
     address = web3.utils.toChecksumAddress(address);
    if(web3.utils.checkAddressChecksum(address)){
      return address;
    } 
  } catch (error) {
    console.log("error",error);
  }
    return "";
  
}

export async function getCurrentBlockNumber(chain_id){
  let ch = chainObject.find(v=>v.chainId == chain_id)
  const web3 = new Web3(new Web3.providers.HttpProvider(ch.rpc[0]))
  const latest = await web3.eth.getBlockNumber();
  return latest
}

export async function getPastEvents(chain_id,type,address,fromBlock,toBlock){
  fromBlock = parseInt(fromBlock);
  toBlock = parseInt(toBlock);
  let ch = chainObject.find(v=>v.chainId == chain_id)
  const web3 = new Web3(new Web3.providers.HttpProvider(ch.rpc[0]))
  
  let abi;
  if(type == 'erc20'){
    abi = ERC20;
  }else{
    abi = ERC721;
  }
  // let addr = validateAddress(address);
  let addr = address;
  let contract = new web3.eth.Contract(abi,addr);
  let pastEvents =[];
  try{
   pastEvents = await contract.getPastEvents('allEvents', {fromBlock: fromBlock,toBlock: toBlock});
  }catch(e){
     if(['Returned error: query returned more than 10000 results','Returned error: context deadline exceeded','Returned error: request expired'].includes(e.message)){
        const middle = Math.round((fromBlock + toBlock) / 2);
        let fEvents =  await getPastEvents(chain_id,type,address,fromBlock, middle);
        let tEvents = await getPastEvents(chain_id,type,address,middle + 1, toBlock);
        pastEvents = [...fEvents,...tEvents];
     }
  }
  pastEvents = pastEvents.sort((a,b) => (a.blockNumber > b.blockNumber) ? 1 : ((b.blockNumber > a.blockNumber) ? -1 : 0))
  return pastEvents
}

export function getSoliditySha3(data){
  if(Array.isArray(data)){
    return web3.utils.soliditySha3(...data)
  }else{
    return web3.utils.soliditySha3(data)
  }
}
export async function getWeb3Signature(message,eth_address){
   return await  web3.eth.personal.sign(message,eth_address);
}

export async function getRecoverAddress(message, signature){
  if(typeof message =='object'){
    return await web3.eth.accounts.recover(message);
  }else{
    return await web3.eth.accounts.recover(message, signature);
  }
  
}