import React from 'react';

import { connect } from 'react-redux';
import {ConnectStep} from '../../components/ConnectStep'
import Pxdrops from '../../components/px-drops'

import Walletgroup from '../../components/Walletgroup'

import {userLogin,userReset} from "../../services/actions"

function Connect(props) {
    const {user,activeDrop,dropDuration} = props;

    return (
        <>
           <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 pb-5">
                       
                        <ConnectStep src={activeDrop.data.logo_url} step_number="1" title="Choose Your Wallet" {...props} />
                        <div className="walletRegister mt-4">
                            <p>Select your wallet containing the eligible NFT to complete your registration.</p>
                         
                            <Walletgroup  />

                        </div>
                        {/* <div className="row justify-content-center">
                <div className="col-lg-12 nft-drops pt-5">
                    <Pxdrops {...props}/>
                  </div>
                </div> */}
          </div>
          </div>
          </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    page: state.page,
    activeDrop:state.activeDrop,
    dropDuration:state.activeDrop,
  })
  
  const mapDispatchToProps = {
    userLogin,
    userReset
  }

export default connect(mapStateToProps, mapDispatchToProps)(Connect)

