import React from 'react'
import { connect } from 'react-redux'

import {ConnectStep} from '../../components/ConnectStep'
import Instructions from '../../components/instructions'
import Recommend from '../../components/Recommend'
import Pxdrops from '../../components/px-drops'

import {showUTCTime,addToCalendar} from "../../utils/index"

function Complete(props) {
    const {user,activeDrop} = props;
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 ">
                        <ConnectStep src={activeDrop.data.logo_url} step_number="2" title="Complete Your Registration" {...props}/>

                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card registrationComplete">
                                    <img src={user.dropNft.tokenInfo.image} alt={user.dropNft.name} />
                                    <div className="registration_label">
                                        <label><i className="ri-check-double-fill me-1"></i> Eligible!</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="text-white mt-4 mb-5">
                            <p className="card-text d-flex align-items-center"><i className="ri-time-line me-2" /><span className='text-decoration-underline'  onClick={()=>{addToCalendar(activeDrop.data)}} style={{cursor:"pointer"}}>   {showUTCTime(activeDrop.data.drp_date_time_utc)}</span><span className="ps-2" style={{color: "#00D1DE"}}>UTC</span></p>
                            <p className="card-text d-flex align-items-center"><i className="ri-user-3-line me-2" /> {activeDrop.data.total_applied} Registered so far!</p>
                            <p className="card-text d-flex align-items-center"><i className="ri-flag-line me-2" /> {activeDrop.data.available_drops}  Available</p>
                            <p className="card-text d-flex align-items-center"><i className="ri-store-line me-2" /> Sponsored by    {activeDrop.data.sponsored_display_name}</p>
                        </div>
                        <div className="card condition mt-5">
                            <div className="d-flex">
                                <i className="ri-ticket-line"></i>
                                <p className='ps-3'>If you claim a reward, you may need to provide an email or mailing address to receive your drop.  </p>
                            </div>
                        </div>
                        <Instructions/>

                        <div className='mt-4'>
                            <Recommend {...props}/>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 nft-drops pt-5">
                                <Pxdrops {...props}/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user:state.user,
    activeDrop:state.activeDrop,
    dropDuration:state.dropDuration,
    page: state.page
})

const mapDispatchToProps = {
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Complete)
  